import { BlockHeader } from "../blockHeaders/BlockHeader";
import "./otherCompany.scss";

export const OtherCompany = () => {
  return (
    <div className="otherCompanyContainer">
      <h2 className="otherCompanyContainer__header">
        Companies that have enjoyed our rooms
      </h2>
      <BlockHeader
        numberBlock={"01/"}
        nameBlock={"Companies that have enjoyed our rooms"}
        customClassName="blockHeader"
      />
      <div className="otherCompanyContainer__ImageGroup">
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/amazon.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/capgemini.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/chestertons.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/hsbc.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/mcdonalds.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/microsoft.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/monzo.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/network-rail.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/nhs.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/tesco.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/tottenham-hotspur.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>
        <div className="otherCompanyContainer__ImageGroup__item">
          <img src="/img/logo/corporate/uk-power-networks.svg" alt="company" className="otherCompanyContainer__ImageGroup__item--img" />
        </div>

      </div>
    </div>
  );
};

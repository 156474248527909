import { Link } from "react-router-dom";
import "./footer.scss";

export const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerBlock">
        <img src="/img/logo/logo-breakin.svg" alt="logo" />
        <div className="footer">
          <Link className="footer--link" to="/">
            Home
          </Link>
          <Link className="footer--link" to="/">
            About The Game
          </Link>
          <Link className="footer--link" to="/">
            Rooms
          </Link>
          <Link className="footer--link" to="/">
            FAQ
          </Link>
          <Link className="footer--link" to="/">
            Prices
          </Link>
          <Link className="footer--link" to="/">
            Gallery
          </Link>
          {/* <Link className="footer--link" to="/">
            Terms And Conditions
          </Link> */}
          <Link
            className="footer--link"
            to="https://breakinescaperooms.co.uk/bundles/breakin/terms-and-conditions-final.pdf"
          >
            Terms And Conditions
          </Link>
          <Link className="footer--link" to="/">
            Contact
          </Link>
        </div>
        <div className="social">
          <a
            className="social--owl"
            href="https://www.tripadvisor.co.uk/Attraction_Review-g186338-d12083343-Reviews-Breakin_Escape_Rooms-London_England.html"
            target="_blank"
          >
            <img src="/img/logo/owl.png" alt="twitter" />
          </a>
          <a
            className="social--fb"
            href="https://www.facebook.com/breakinescaperooms/"
            target="_blank"
          >
            <img src="/img/logo/Facebook.png" alt="twitter" />
          </a>
          <a
            className="social--twi"
            href="https://twitter.com/BreakinEscape"
            target="_blank"
          >
            <img src="/img/logo/twi.png" alt="twitter" />
          </a>
          <a
            className="social--inst"
            href="https://www.instagram.com/explore/locations/1791909637731727/breakin-escape-rooms/"
            target="_blank"
          >
            <img src="/img/logo/inst.png" alt="instagram" />
          </a>
        </div>
      </div>

      <div className="footerBlockMobile">
        <div className="footerUp">
          <img src="/img/logo/logo-breakin.svg" alt="logo" />
          <p>&#xa9; Breakin 2023</p>
        </div>
        <div className="footerNobile">
          <p>Follow us</p>
          <div className="social">
            <a
              className="social--owl"
              href="https://www.tripadvisor.co.uk/Attraction_Review-g186338-d12083343-Reviews-Breakin_Escape_Rooms-London_England.html"
              target="_blank"
            >
              <img src="/img/logo/owl.png" alt="twitter" />
            </a>
            <a
              className="social--fb"
              href="https://www.facebook.com/breakinescaperooms/"
              target="_blank"
            >
              <img src="/img/logo/Facebook.png" alt="twitter" />
            </a>
            <a
              className="social--twi"
              href="https://twitter.com/BreakinEscape"
              target="_blank"
            >
              <img src="/img/logo/twi.png" alt="twitter" />
            </a>
            <a
              className="social--inst"
              href="https://www.instagram.com/explore/locations/1791909637731727/breakin-escape-rooms/"
              target="_blank"
            >
              <img src="/img/logo/inst.png" alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { CustomModal } from "../UI/modal/CustomModal";
import { ScheduleMap } from "./ScheduleMap";
import { Loader } from "../UI/loader/Loader";
import { BlockHeader } from "../blockHeaders/BlockHeader";
import api from "../../core/api";
import "./booking.scss";

export const Booking = ({ dateModalIsOpen, openDateModal, closeDateModal, link }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const [htmlContent, setHtmlContent] = useState(null);
  const [htmlContent2, setHtmlContent2] = useState(null);
  const [htmlContent3, setHtmlContent3] = useState(null);
  const [htmlContent4, setHtmlContent4] = useState(null);
  //5
  const [htmlContent6, setHtmlContent6] = useState(null);
  const [htmlContent7, setHtmlContent7] = useState(null);
  const [htmlContent8, setHtmlContent8] = useState(null);
  const [htmlContent9, setHtmlContent9] = useState(null);

  const [fetchDataDay, setFetchDataDay] = useState(0);
  const [fetchDataDay2, setFetchDataDay2] = useState(0);
  const [fetchDataDay3, setFetchDataDay3] = useState(0);
  const [fetchDataDay4, setFetchDataDay4] = useState(0);
  //5
  const [fetchDataDay6, setFetchDataDay6] = useState(0);
  const [fetchDataDay7, setFetchDataDay7] = useState(0);
  const [fetchDataDay8, setFetchDataDay8] = useState(0);
  const [fetchDataDay9, setFetchDataDay9] = useState(0);

  const [isFetching, setIsFetching] = useState(false);
  const [isFetching2, setIsFetching2] = useState(false);
  const [isFetching3, setIsFetching3] = useState(false);
  const [isFetching4, setIsFetching4] = useState(false);
  const [isFetching6, setIsFetching6] = useState(false);
  const [isFetching7, setIsFetching7] = useState(false);
  const [isFetching8, setIsFetching8] = useState(false);
  const [isFetching9, setIsFetching9] = useState(false);

  const [links, setLinks] = useState([]);

  useEffect(() => {
    fetching(1, fetchDataDay);
  }, [fetchDataDay]);

  useEffect(() => {
    fetching2(2, fetchDataDay2);
  }, [fetchDataDay2]);

  useEffect(() => {
    fetching3(3, fetchDataDay3);
  }, [fetchDataDay3]);

  useEffect(() => {
    fetching4(4, fetchDataDay4);
  }, [fetchDataDay4]);

  useEffect(() => {
    fetching6(6, fetchDataDay6);
  }, [fetchDataDay6]);

  useEffect(() => {
    fetching7(7, fetchDataDay7);
  }, [fetchDataDay7]);

  useEffect(() => {
    fetching8(8, fetchDataDay8);
  }, [fetchDataDay8]);

  useEffect(() => {
    fetching9(9, fetchDataDay9);
  }, [fetchDataDay9]);

  useEffect(() => {
    if (
      htmlContent &&
      htmlContent2 &&
      htmlContent3 &&
      htmlContent4 &&
      htmlContent6 &&
      htmlContent7 &&
      htmlContent8 &&
      htmlContent9
    ) {
      setLinks(document.getElementsByClassName("can-book"));
    }
  });

  const fetchData = async (room, day, setContent, setLoaderStatus) => {
    try {
      setLoaderStatus(true);
      const response = await api.get(`quests/schedule/${room}/${day}`, {
        responseType: "text",
      });
      setContent(response.data);
    } catch (error) {
      console.error(error);
      <Loader />;
    } finally {
      setLoaderStatus(false);
    }
  };

  const fetching = async (room, day) => {
    await fetchData(room, day, setHtmlContent, setIsFetching);
  };

  const fetching2 = async (room, day) => {
    await fetchData(room, day, setHtmlContent2, setIsFetching2);
  };

  const fetching3 = async (room, day) => {
    await fetchData(room, day, setHtmlContent3, setIsFetching3);
  };

  const fetching4 = async (room, day) => {
    await fetchData(room, day, setHtmlContent4, setIsFetching4);
  };
  const fetching6 = async (room, day) => {
    await fetchData(room, day, setHtmlContent6, setIsFetching6);
  };

  const fetching7 = async (room, day) => {
    await fetchData(room, day, setHtmlContent7, setIsFetching7);
  };

  const fetching8 = async (room, day) => {
    await fetchData(room, day, setHtmlContent8, setIsFetching8);
  };

  const fetching9 = async (room, day) => {
    await fetchData(room, day, setHtmlContent9, setIsFetching9);
  };

  useEffect(() => {
    for (var i = 0; i < links.length; i++) {
      links[i].addEventListener("click", clickEvent);
    }
    return () => {
      for (var i = 0; i < links.length; i++) {
        links[i].removeEventListener("click", clickEvent);
      }
    };
  }, [
    links,
    htmlContent,
    htmlContent2,
    htmlContent3,
    htmlContent4,
    htmlContent6,
    htmlContent7,
    htmlContent8,
    htmlContent9,
  ]);

  const clickEvent = (event) => {
    event.preventDefault();
    setSelectedDate(event.target.getAttribute("data-slotdate"));
    setSelectedTime(event.target.getAttribute("data-slottime"));
    setSelectedRoom(event.target.getAttribute("data-roomid"));
    openDateModal();
  };

  useEffect(() => {
  }, [dateModalIsOpen]);

  // сделать в useEffect removeEventListener
  //const v state
  // верстка в отдельный компонент

  const changeButton = document.getElementById("reservation-panel-next-1");
  const changeButton2 = document.getElementById("reservation-panel-next-2");
  const changeButton3 = document.getElementById("reservation-panel-next-3");
  const changeButton4 = document.getElementById("reservation-panel-next-4");
  const changeButton6 = document.getElementById("reservation-panel-next-6");
  const changeButton7 = document.getElementById("reservation-panel-next-7");
  const changeButton8 = document.getElementById("reservation-panel-next-8");
  const changeButton9 = document.getElementById("reservation-panel-next-9");

  const changeButtonBack1 = document.getElementById("reservation-panel-prev-1");
  const changeButtonBack2 = document.getElementById("reservation-panel-prev-2");
  const changeButtonBack3 = document.getElementById("reservation-panel-prev-3");
  const changeButtonBack4 = document.getElementById("reservation-panel-prev-4");
  const changeButtonBack6 = document.getElementById("reservation-panel-prev-6");
  const changeButtonBack7 = document.getElementById("reservation-panel-prev-7");
  const changeButtonBack8 = document.getElementById("reservation-panel-prev-8");
  const changeButtonBack9 = document.getElementById("reservation-panel-prev-9");

  if (!isFetching) {
    if (changeButton) {
      changeButton.addEventListener("click", function (event) {
        setFetchDataDay(fetchDataDay + 8);
      });
    }
    if (changeButton2) {
      changeButton2.addEventListener("click", function (event) {
        setFetchDataDay2(fetchDataDay2 + 8);
      });
    }
    if (changeButton3) {
      changeButton3.addEventListener("click", function (event) {
        setFetchDataDay3(fetchDataDay3 + 8);
      });
    }
    if (changeButton4) {
      changeButton4.addEventListener("click", function (event) {
        setFetchDataDay4(fetchDataDay4 + 8);
      });
    }
    if (changeButton6) {
      changeButton6.addEventListener("click", function (event) {
        setFetchDataDay6(fetchDataDay6 + 8);
      });
    }
    if (changeButton7) {
      changeButton7.addEventListener("click", function (event) {
        setFetchDataDay7(fetchDataDay7 + 8);
      });
    }
    if (changeButton8) {
      changeButton8.addEventListener("click", function (event) {
        setFetchDataDay8(fetchDataDay8 + 8);
      });
    }
    if (changeButton9) {
      changeButton9.addEventListener("click", function (event) {
        setFetchDataDay9(fetchDataDay9 + 8);
      });
    }

    if (changeButtonBack1) {
      changeButtonBack1.addEventListener("click", function (event) {
        setFetchDataDay(fetchDataDay - 8);
      });
    }
    if (changeButtonBack2) {
      changeButtonBack2.addEventListener("click", function (event) {
        setFetchDataDay2(fetchDataDay2 - 8);
      });
    }
    if (changeButtonBack3) {
      changeButtonBack3.addEventListener("click", function (event) {
        setFetchDataDay3(fetchDataDay3 - 8);
      });
    }
    if (changeButtonBack4) {
      changeButtonBack4.addEventListener("click", function (event) {
        setFetchDataDay4(fetchDataDay4 - 8);
      });
    }
    if (changeButtonBack6) {
      changeButtonBack6.addEventListener("click", function (event) {
        setFetchDataDay6(fetchDataDay6 - 8);
      });
    }
    if (changeButtonBack7) {
      changeButtonBack7.addEventListener("click", function (event) {
        setFetchDataDay7(fetchDataDay7 - 8);
      });
    }
    if (changeButtonBack8) {
      changeButtonBack8.addEventListener("click", function (event) {
        setFetchDataDay8(fetchDataDay8 - 8);
      });
    }
    if (changeButtonBack9) {
      changeButtonBack9.addEventListener("click", function (event) {
        setFetchDataDay9(fetchDataDay9 - 8);
      });
    }
  }

  // useEffect(() => {
  //   update()
  //   console.log('update')
  //   }, [modalIsOpen, mobileMenuIsOpen]);

  return (
    // <Element name="linkBookingComponent">
    <Element name={`${link}`}>
      <div>
        <CustomModal
          modalIsOpen={dateModalIsOpen}
          closeModal={closeDateModal}
          contentLabel={"Example Modal"}
          className={"modal-content2"}
          overlayClassName={"modal2"}
          date={selectedDate}
          time={selectedTime}
          room={Number(selectedRoom)}
          formWithDate
        />
      </div>
      <div className="bookingContainer">
        <h2>BOOKINGS</h2>

        <BlockHeader
          numberBlock={"06 /"}
          nameBlock={"BOOKINGS"}
          customClassName="blockHeaderWhite"
        />
        <ScheduleMap
          htmlContent={htmlContent}
          htmlContent2={htmlContent2}
          htmlContent3={htmlContent3}
          htmlContent4={htmlContent4}
          htmlContent6={htmlContent6}
          htmlContent7={htmlContent7}
          htmlContent8={htmlContent8}
          htmlContent9={htmlContent9}
          // htmlContent5={htmlContent5}
          // isFetching5={isFetching5}
          isFetching={isFetching}
          isFetching2={isFetching2}
          isFetching3={isFetching3}
          isFetching4={isFetching4}
          isFetching6={isFetching6}
          isFetching7={isFetching7}
          isFetching8={isFetching8}
          isFetching9={isFetching9}
        />
      </div>
    </Element>
  );
};

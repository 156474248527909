import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../../loader/Loader";
import "..//voucher/voucher.scss";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../core/api";

export const PaymentFailure = ({
  acc,
  session_id,
  renderIsNeed,
  setRenderIsNeed,
  modalIsOpen,
  closeModal,
}) => {
  const [answer, setAnswer] = useState("");
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (modalIsOpen === false) {
      navigate("/");
    }
  }, [modalIsOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `quests/stripe-cancel?acc=${acc}&session_id=${session_id}`,
          {
            responseType: "text",
          }
        );
        setAnswer(JSON.parse(response.data));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [acc, session_id]);

  useEffect(() => {
    if (answer && answer.success === true && !isPaymentSuccessful) {
    //   toast.fail("Payment Failure!");
      setIsPaymentSuccessful(false);
    }
  }, [answer, isPaymentSuccessful]);

  return (
    <div className="voucherField">
      <ToastContainer />
      {answer && answer.success === true ? (
        <>
          <h1>Something went wrong :(</h1>
        </>
      ) : answer && answer.success === false ? (
        <>
          {/* <h1>Greetings adventurer!</h1> */}
          <h1>Something went wrong :(</h1>
        </>
      ) : (
        <Loader className={"loaderGroup"}/>
      )}
    </div>
  );
};

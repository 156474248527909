import { useState } from "react";
import { Button } from "../UI/button/Button";
import { ButtonLink } from "../UI/button/ButtonLink";
import { CustomModal } from "../UI/modal/CustomModal";
import "./mobileMenu.scss";
import { Link, useNavigate } from "react-router-dom";

export const MobileMenu = ({ closeModal,bookLinkButton }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  // const scrollUpTarget = () => {
  //   window.scrollTo(0, 0);
  // };

  // const openVoucher = () => {
  //   setModalIsOpen(true);
  // };

  // const handleModalClose = () => {
  //   closeModal();
  // };

  const handleLinkClick = () => {
    if (window.location.pathname !== "/") {
      navigate("/");
    }
    // handleModalClose();
    closeModal();
    // window.scrollTo(0, 0);
  };

  return (
    <div>
      <div id="dropdown" className="dropdown">
        <div className="dropdown__mobileMenu">
          <img
            className="dropdown__mobileMenu--img"
            src="/img/logo/logo-breakin.svg"
            alt="breakin"
          ></img>
          <Link
            to="/outdoor"
            onClick={handleLinkClick}
            className="dropdown__mobileMenu--li smallLi"
          >
            Outdoor
          </Link>
          <Link
            to="/corporate"
            onClick={handleLinkClick}
            className="dropdown__mobileMenu--li smallLi"
          >
            Corporate
          </Link>
          <ButtonLink
            text={"Home"}
            className={"dropdown__mobileMenu--li"}
            targetComponent={"homeComponent"}
            onClick={handleLinkClick}
          />
          <ButtonLink
            text={"who we are"}
            className={"dropdown__mobileMenu--li"}
            targetComponent={"aboutCompanyComponent"}
            onClick={handleLinkClick}
          />
          <ButtonLink
            text={"rooms"}
            className={"dropdown__mobileMenu--li"}
            targetComponent={"roomsComponent"}
            onClick={handleLinkClick}
          />
          <ButtonLink
            text={"FAQ"}
            className={"dropdown__mobileMenu--li"}
            targetComponent={"accordionComponent"}
            onClick={handleLinkClick}
          />
          <ButtonLink
            text={"Prices"}
            className={"dropdown__mobileMenu--li"}
            targetComponent={"priceComponent"}
            onClick={handleLinkClick}
          />
          <ButtonLink
            text={"Gallery"}
            className={"dropdown__mobileMenu--li"}
            targetComponent={"galleryComponent"}
            onClick={handleLinkClick}
          />
          <ButtonLink
            text={"Contact"}
            className={"dropdown__mobileMenu--li"}
            targetComponent={"contactsComponent"}
            onClick={handleLinkClick}
          />
          {/* <ButtonLink
            text={"Outdoor"}
            className={"dropdown__mobileMenu--li smallLi"}
            targetComponent={"/outdoor"}
            onClick={handleLinkClick}
          /> */}

          <div className="navMenuBlock--buttons">
            <Button
              text={"Voucher"}
              className={"voucher-button"}
              onClick={() => setModalIsOpen(true)}
            />
            <ButtonLink
              text={"Bookings"}
              className={"buttonPink"}
              targetComponent={`${bookLinkButton}`}
              onClick={handleLinkClick}
            />
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <CustomModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          contentLabel={"Example Modal"}
          className={"modal-content2"}
          overlayClassName={"modal2"}
          form
        />
      )}
    </div>
  );
};

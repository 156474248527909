import React, { useState } from "react";
import { Button } from "../UI/button/Button";
import { NavMenu } from "../navMenu/NavMenu";
import { CustomModal } from "../UI/modal/CustomModal";
import { useEffect } from "react";
import { ButtonLink } from "../UI/button/ButtonLink";
import { Element } from "react-scroll";
import "./introduce.scss";

export const Introduce = ({
  dateModalIsOpen,
  openDateModal,
  closeDateModal,

  modalIsOpen,
  openModal,
  closeModal,

  mobileMenuIsOpen,
  openMobileMenuModal,
  closeMobileMenuModal,

  videoIsOpen,
  openVideoModal,
  closeVideoMenuModal,

  scrollToTop,
  bookLinkButton
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <Element name="homeComponent">
      <div className="introduce">
        <div>
          <NavMenu
            dateModalIsOpen={dateModalIsOpen}
            openDateModal={openDateModal}
            closeDateModal={closeDateModal}

            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}

            mobileMenuIsOpen={mobileMenuIsOpen}
            openMobileMenuModal={openMobileMenuModal}
            closeMobileMenuModal={closeMobileMenuModal}

            scrollToTop={scrollToTop}
            bookLinkButton={bookLinkButton}
          />
        </div>
        <div className="introduceBlock">
          <div className="introduceBlock__group">
            <img
              className="introduceBlock__group--img"
              src="/img/logo/titlubreakinen.png"
              alt="escape rooms"
            />
            <h1 className="introduceBlock__group--text">
              The most immersive <br />
              <span>Escape Room </span>
              <br />
              experience in <br />
              London
            </h1>
            {/* <h1 className="introduceBlock__group--text">
              The most immersive <br />
              <span>Escape Room </span>
              <br />
              experience in <br />
              London
            </h1> */}

            <div className="introduceBlock--buttons">
              <div className="buttonCase">
                <Button className={"playTrailer"} onClick={openVideoModal} />
                <p className="playTrailer--triangle"></p>
                <p className="playTrailer--text">Play Trailer</p>
              </div>

              <div className="buttonCase">
                <Button
                  className={"buttonDetailsMobile"}
                  text={"Watch Trailer"}
                  onClick={openVideoModal}
                />
                <p className="buttonDetailsMobile--yt"></p>
              </div>

              <ButtonLink
                text={"More Details"}
                className={"buttonDetails"}
                targetComponent={"aboutCompanyComponent"}
              />
              <ButtonLink
                text={"Book a Room"}
                className={"buttonBook"}
                targetComponent={"linkBookingComponent"}
              />
            </div>
          </div>

          {isMobile ? (
            <img
              className="breakingMobileImg"
              src={"/img/logo/bg_actions_july.jpg"}
              alt={"Breakin Escape Rooms"}
            />
          ) : (
            <video className="background-video" autoPlay loop muted>
              <source src="/img/media/video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <CustomModal
          modalIsOpen={videoIsOpen}
          closeModal={closeVideoMenuModal}
          video
          videoSrc={"/img/media/video.mp4"}
          contentLabel={"Video Modal"}
          className={"Modal"}
          overlayClassName={"Overlay"}
        />
      </div>

      {/* mobile new */}

      {/* <div className="introduceNewMobile">
        <NavMenu /> */}

      {/* <div className="introduceNewMobile__container">
          <div className="introduceNewMobile__text">
            <h1 className="introduceNewMobile__text--headWhite">
              Augmented Reality
            </h1>
            <h2 className="introduceNewMobile__text--headPink">
              Outdoor City Experience
            </h2>
            <p className="introduceNewMobile__text--paragraph">
              Try the latest augmented reality outdoor escape game experience
              with one of the leading immersive entertainment companies.
            </p>
          </div>
          <div className="introduceNewMobile__buttons">
            <ButtonLink
              text={"Book Outdoor Game"}
              className={"buttonBook newButtonBook"}
              targetComponent={"linkBookingComponent"}
            />
            <div className="">
              <ButtonLink
                text={"Corporate Bookings"}
                className={"buttonCorporate"}
                targetComponent={"linkBookingComponent"}
              />
              <p className="textForButtonCorporate">12+ participants</p>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      {/* </div> */}
    </Element>
  );
};

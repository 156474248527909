import { useState } from "react";
import { ButtonLink } from "../UI/button/ButtonLink";
import { BlockHeader } from "../blockHeaders/BlockHeader";
import "./roomComponent.scss";

export const OutdoorRoom = ({ outdoorRoomData, bookLinkButton }) => {

  const [showText, setShowText] = useState(false);
  const initialParagraphs = 2;

  const displayedParagraphs = showText
    ? outdoorRoomData.description
    : outdoorRoomData.description.slice(0, initialParagraphs);

  const style = {
    backgroundColor: outdoorRoomData.bcgColor,
  };

  const renderPuzzles = (difficulty, puzzleColor) => {
    let puzzles = [];
    for (let i = 0; i < 5; i++) {
      if (i < difficulty) {
        puzzles.push(<p key={i} className={puzzleColor}></p>);
      } else {
        puzzles.push(
          <p
            key={i}
            className={
              puzzleColor === "whitePuzzle" ? "opacityPuzzle" : "greyPuzzle"
            }
          ></p>
        );
      }
    }
    return puzzles;
  };


  console.log('bookLinkButton',bookLinkButton)

  return (
    <div>
      <div className="rommsBox">
        <section
          className={outdoorRoomData.roomHash}
          data-background={outdoorRoomData.background}
        >
          <div className="contentOfRooms">
            <div className="paragraph">
              <h2 className="slider" data-delay="0">
                <span>
                  <img src={outdoorRoomData.imageUrl} alt="Room" />
                </span>
                {outdoorRoomData.title}
              </h2>
              {outdoorRoomData.description.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
            <div className="roomButtons">
              <ButtonLink
                text={"Book This Game"}
                className={"bookingRoom"}
                targetComponent={`${bookLinkButton}`}
              />
            </div>
            <div className="difficulty">
              <p>Difficulty: </p>
              <div className="difficulty--images">
                <img src="/img/logo/dotact2.png" alt="fullDot" />
                <img src="/img/logo/dotact2.png" alt="fullDot" />
                <img src="/img/logo/dotact2.png" alt="fullDot" />
                <img src="/img/logo/dotina2.png" alt="emptyDot" />
                <img src="/img/logo/dotina2.png" alt="emptyDot" />
              </div>
              <p>
                Capacity: <b>2-100 players</b>
              </p>
            </div>
          </div>
        </section>
      </div>
      <BlockHeader
        numberBlock={"03 /"}
        nameBlock={"ROOMS"}
        customClassName="blockHeader"
      />
      <div className="roomMob mobOutdoor" style={style}>
        <section>
          <img
            src={outdoorRoomData.imageMobile}
            className={outdoorRoomData.imageClass}
            alt="wizz"
          />
          <div className="descriptionOfRoom">
            <div className="difficultyMob" style={style}>
              <div className="difficulPosition">
                <div className="difficultyGroup">
                  {renderPuzzles(
                    outdoorRoomData.difficulty,
                    outdoorRoomData.puzzleColor
                  )}
                </div>
                <span
                  className={
                    outdoorRoomData.puzzleColor === "pinkPuzzle"
                      ? "blackDifficult"
                      : "whiteDifficult"
                  }
                >
                  difficulty
                </span>
              </div>
            </div>

            <h2
              className={
                outdoorRoomData.bcgColor === "white"
                  ? "blackTitle"
                  : "whiteTitle"
              }
            >
              {outdoorRoomData.title}
            </h2>

            <div
              className={`${outdoorRoomData.mobileTextWrapper} ${
                !showText ? "fade-out" : ""
              }`}
            >
              <div className="mobile-text">
                {displayedParagraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>

            <button
              onClick={() => setShowText(!showText)}
              className={`showButton ${
                outdoorRoomData.mobileTextWrapper === "mobileTextWrapper-white"
                  ? "black-text"
                  : "white-text"
              }`}
            >
              {showText ? "less details" : "more details"}
            </button>
            <div className="buttonsGroup">
              <ButtonLink
                text={"Book This Game"}
                className={"buttonBook"}
                targetComponent={`${bookLinkButton}`}
              />
              <span
                className={
                  outdoorRoomData.puzzleColor === "pinkPuzzle"
                    ? "whiteBcgTable"
                    : "blackBcgTable"
                }
              >
                <p>2-12</p>
                players
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

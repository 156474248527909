import { AccordionItem } from "./AccordionItem";
import "./accordion.scss";
import { Button } from "../UI/button/Button";
import { Element } from "react-scroll";
import { useState } from "react";
import { BlockHeader } from "../blockHeaders/BlockHeader";

export const Accordion = ({accordionType = 'rooms'}) => {
  const [openQuestion, setOpenQuestion] = useState(null);

  if(accordionType === 'outdoors') {
    return (
        <Element name="accordionComponent">
          <div className="faq">
            <BlockHeader
                numberBlock={"07 /"}
                nameBlock={"F.A.Q"}
                customClassName="blockHeader"
            />
            <div className="faqLeft">
              {/* <p className="faqLeft--img"></p> */}
              <div className="faqLeft--text">
                <h2>F.A.Q</h2>
                <p>
                  Have a question? Please use the button below to ask us anything
                  and we will get back to you shortly.
                </p>
                <a href={'mailto:info@breakinescaperooms.co.uk'} className={"buttonFaq"}>Ask a Question!</a>
              </div>
            </div>
            <div className="questions">
              <ul id="basics" className="questionsGroup">
                <AccordionItem
                    question="What are outdoor escape games?"
                    answer="Outdoor escape games combine all of the best parts of escape rooms and treasure hunts in an outdoor setting. This gives players the excitement of an escape room, whilst also being able to explore the city! Our live action adventure games take place in the City of London and give players the chance to see some amazing sights, whilst also solving puzzles. "
                    id="1"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="What happens on the day?"
                    answer="When it comes to the day of your game, you will need to arrive 10 minutes before your start time. You will be briefed by your games master who will also provide you with an iPad and an action pack which features a whole bunch of awesome gadgets. After that, you will head out onto the streets of London to complete your mission! "
                    id="2"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="What happens if it rains?"
                    answer="Our games will usually run rain or shine. We have routes that make use of indoor spaces for days when the weather is bad. If there are particularly extreme weather conditions, then we may have to reschedule your games."
                    id="3"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="What do I need to bring with me?"
                    answer="You don’t need to bring anything with you, we’ve got everything here, but your team may need access to Facebook Messenger! Your team will be given an iPad and an action pack with all of the gadgets you will need. We do recommend wearing comfortable shoes, as you will be walking around the city. "
                    id="4"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="How many people can play outdoor escape games?"
                    answer="We can host anywhere from 2-100 players at any one time. We usually recommend teams of between 2-5 and each team will be equipped with an iPad and action pack. For larger groups, please contact us to arrange your booking. "
                    id="5"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="If we have more than one team, will we all be on the same route?"
                    answer="No, if you’ve got bigger teams, you will be on separate routes and with staggered start times to make sure everyone isn’t going in the same direction. "
                    id="6"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
              </ul>
            </div>
          </div>
        </Element>
    );
  }else{

    return (
        <Element name="accordionComponent">
          <div className="faq">
            <BlockHeader
                numberBlock={"07 /"}
                nameBlock={"F.A.Q"}
                customClassName="blockHeader"
            />
            <div className="faqLeft">
              {/* <p className="faqLeft--img"></p> */}
              <div className="faqLeft--text">
                <h2>F.A.Q</h2>
                <p>
                  Have a question? Please use the button below to ask us anything
                  and we will get back to you shortly.
                </p>
                <a href={'mailto:info@breakinescaperooms.co.uk'} className={"buttonFaq"}>Ask a Question!</a>
              </div>
            </div>
            <div className="questions">
              <ul id="basics" className="questionsGroup">
                <AccordionItem
                    question="What is the minimum age to participate?"
                    answer="The minimum age to participate unaccompanied by an adult is
            16. Under 16's need to have at least one paying adult
            participant in the room with them during the game. We would
            also like to mention that the games have been designed for a
            more mature audience and children under the age of 12 may
            not fully enjoy the experience due to the complexity of the
            puzzles. The recommended room for younger players is The
            Flying Dutchman."
                    id="1"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="What if I get
            stuck on a puzzle?"
                    answer="A staff member
            will be monitoring your progress on CCTV ready to provide
            you with clues when needed so as to move forward in the
            game."
                    id="4"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question=" What do I need to
            bring with me?"
                    answer="All you need
            is a working brain."
                    id="5"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="Can we play the
            same room more than once?"
                    answer="Yes, you
            could, but you will already know the solutions to all the
            puzzles, so you will not be eligible for the Hall of Fame as
            you will escape really fast. Usually if you fail to escape
            within the allocated 60 minutes but you are close to the
            end, we will let you finish the game. If you succeed to
            escape, try your skills in the other rooms, we have 6
            completely different stories!"
                    id="6"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="What time should I
            arrive?"
                    answer="Instructions will be provided in the confirmation email
            that you will receive once you have made the booking online.
            Usually you should arrive 15 minutes before your reservation
            time for a short training and introduction session. Please
            try not to be late as the reservations are fixed time
            intervals."
                    id="7"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question=" How can I book one
            of your rooms?"
                    answer="Reservations can only be made online through our website.
            For parties, team-building activities and other events you
            can send us an email to info@breakinescaperooms.co.uk and
            we’ll be happy to help!"
                    id="8"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question="How can I pay?"
                    answer=" Payments are
            only made online when booking a timeslot. If you would like
            to add players to your team for a pre-existing booking you
            can also pay by cash at our venue."
                    id="9"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question=" Can I bring a 7th
            player?"
                    answer=" In 6 of our
            themed rooms you can add an extra 7th player. The only room
            where this is not possible is our Wizarding School room; for
            health and safety reasons. Do keep in mind that while it is
            possible, for a more enjoyable experience we wholeheartedly
            recommend splitting a team of 7 between 2 different rooms as
            this means each player will have more to do and enjoy the
            rooms as they were intended! The 7’th player can pay on the
            door by card."
                    id="10"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question=" What happens if we
            are running late for our booking?"
                    answer=" Depending on
            how late you are running could mean a number of different
            options. If you cannot make it 15 minutes earlier than your
            game time - as we request - this normally shouldn’t affect
            your game. However, any later than this and we may either
            have to deduct time from your game or if you are 15 minutes
            later than the game starting time itself, we may have to
            cancel the game entirely with no refund."
                    id="11"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question=" Do you offer
            parking?"
                    answer=" There is no
            public parking at our venue. You may find parking on the
            streets nearby, but this is not guaranteed. Please ensure
            you have enough time to find appropriate parking should you
            arrive by car."
                    id="12"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question=" Do you serve any
            drinks?"
                    answer=" We do not
            serve any food or beverages. There are numerous cafes and
            restaurants in our neighbourhood for before or after your
            game."
                    id="13"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
                <AccordionItem
                    question=" What is your level
            of accessibility?"
                    answer=" Currently, due
            to the nature of the building that we are in, we are not
            wheelchair accessible. Some rooms contain, flashing lights,
            loud sounds, puzzles that rely on colour vision, climbing."
                    id="14"
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                />
              </ul>
            </div>
          </div>
        </Element>
    );

  }
};

import { ButtonLink } from "../components/UI/button/ButtonLink";
import { Accordion } from "../components/accordion/Accordion";
import { BookingOutdoor } from "../components/booking/BookingOutdoor";
import { ContactInfo } from "../components/contactInfo/ContactInfo";
import { Features } from "../components/features/Features";
import { Footer } from "../components/footer/Footer";
import { NavMenu } from "../components/navMenu/NavMenu";
import { PriceBlockOutdoors } from "../components/priceBlockOutdoors/PriceBlockOutdoors";
import { Reviews } from "../components/reviews/Reviews";
import { OutdoorMap } from "./OutdoorMap";
import { animateScroll as scroll } from "react-scroll";
// import { PhotoBox } from "../components/photoBox/PhotoBox";
import "./outdoor.scss";

export const OutdoorPage = ({
  dateModalIsOpen,
  openDateModal,
  closeDateModal,

  modalIsOpen,
  openModal,
  closeModal,

  mobileMenuIsOpen,
  openMobileMenuModal,
  closeMobileMenuModal,
}) => {
  // const scrollToTop = () => {
  //   scroll.scrollToTop();
  // };

  return (
    <div>
      <div className="introduceNewMobile">
        <div className="introduceNewMobile--nav">
          <NavMenu
            dateModalIsOpen={dateModalIsOpen}
            openDateModal={openDateModal}
            closeDateModal={closeDateModal}
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            mobileMenuIsOpen={mobileMenuIsOpen}
            openMobileMenuModal={openMobileMenuModal}
            closeMobileMenuModal={closeMobileMenuModal}
            bookLinkButton={"linkOutdoorBookingComponent"}
          />
        </div>

        <div className="introduceNewMobile__container">
          <div className="introduceNewMobile__text">
            <h1 className="introduceNewMobile__text--headWhite">Augmented Reality</h1>
            <h2 className="introduceNewMobile__text--headPink">Outdoor City Experience</h2>
            <p className="introduceNewMobile__text--paragraph">
              Try the latest augmented reality outdoor escape game experience with one of the
              leading immersive entertainment companies.
            </p>
            <div className="introduceNewMobile__buttons">
              <ButtonLink
                text={"Book Outdoor Game"}
                className={"buttonBook newButtonBook"}
                targetComponent={"linkOutdoorBookingComponent"}
              />
              <div className="">
                <a className={"buttonCorporate"} href={"/corporate"}>
                  Corporate Bookings
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Reviews className={"reviewsOutdoor"} />
      <Features />
      <OutdoorMap bookLinkButton={"linkOutdoorBookingComponent"} />
      <PriceBlockOutdoors />
      {/*<PhotoBox />*/}

      <BookingOutdoor
        dateModalIsOpen={dateModalIsOpen}
        openDateModal={openDateModal}
        closeDateModal={closeDateModal}
        link={"linkOutdoorBookingComponent"}
      />
      <Accordion accordionType={"outdoors"} />
      <ContactInfo />
      <Footer />
    </div>
  );
};

import { useEffect, useState } from "react";
import { CustomModal } from "../UI/modal/CustomModal";
import { Loader } from "../UI/loader/Loader";
import { BlockHeader } from "../blockHeaders/BlockHeader";
import { Element } from "react-scroll";
import api from "../../core/api";
import "./booking.scss";

export const BookingOutdoor = ({
  dateModalIsOpen,
  openDateModal,
  closeDateModal,
  link
  //  modalIsOpen, openModal, closeModal
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [links, setLinks] = useState([]);

  const [htmlContent5, setHtmlContent5] = useState(null);
  const [fetchDataDay, setFetchDataDay] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const ScheduleAdd = {
    accessibility: [
      /*{
        title: "On the 1st floor of the building. No lift access",
        image: "/img/logo/No_lift_access.svg",
      },*/
      {
        title: "Puzzles that rely on colour vision",
        image: "/img/logo/Colour_vision.svg",
      },
    ],
    difficulty: 3,
  };

  const countOfDifficultDots = () => {
    let dotsArray = [];
    for (let i = 0; i < 5; i++) {
      if (i < ScheduleAdd.difficulty) {
        dotsArray.push(<img key={`dotact-${i}`} src="/img/logo/dotact.png" alt="dota" />);
      } else if (i >= ScheduleAdd.difficulty) {
        dotsArray.push(<img key={`dotina-${i}`} src="/img/logo/dotina.png" alt="dotina" />);
      }
    }
    return dotsArray;
  };

  useEffect(() => {
    fetching(5, fetchDataDay);
  }, [fetchDataDay]);

  useEffect(() => {
    if (htmlContent5) {
      setLinks(document.getElementsByClassName("can-book"));
    }
  });

  const fetchData = async (room, day, setContent, setLoaderStatus) => {
    try {
      setLoaderStatus(true);
      const response = await api.get(`quests/schedule/${room}/${day}`, {
        responseType: "text",
      });
      setContent(response.data);
    } catch (error) {
      console.error(error);
      <Loader />;
    } finally {
      setLoaderStatus(false);
    }
  };

  const fetching = async (room, day) => {
    await fetchData(room, day, setHtmlContent5, setIsFetching);
  };

  useEffect(() => {
    for (var i = 0; i < links.length; i++) {
      links[i].addEventListener("click", clickEvent);
    }
    return () => {
      for (var i = 0; i < links.length; i++) {
        links[i].removeEventListener("click", clickEvent);
      }
    };
  }, [links, htmlContent5]);

  const clickEvent = (event) => {
    event.preventDefault();
    setSelectedDate(event.target.getAttribute("data-slotdate"));
    setSelectedTime(event.target.getAttribute("data-slottime"));
    setSelectedRoom(event.target.getAttribute("data-roomid"));
    openDateModal();
  };

  const changeButton = document.getElementById("reservation-panel-next-5");
  const changeButtonBack = document.getElementById("reservation-panel-prev-5");

  if (!isFetching) {
    if (changeButton) {
      changeButton.addEventListener("click", function (event) {
        setFetchDataDay(fetchDataDay + 8);
      });
    }
    if (changeButtonBack) {
      changeButtonBack.addEventListener("click", function (event) {
        setFetchDataDay(fetchDataDay - 8);
      });
    }
  }

  return (
    // <Element name={`${link}`}>

      <div className="outDoorContainer">
        <CustomModal
          modalIsOpen={dateModalIsOpen}
          closeModal={closeDateModal}
          contentLabel={"Example Modal"}
          className={"modal-content2"}
          overlayClassName={"modal2"}
          date={selectedDate}
          time={selectedTime}
          room={Number(selectedRoom)}
          currentPage={"outdoorModal"}
          formWithDate
        />

        <div className="bookingOutdoor">
          <BlockHeader
            numberBlock={"05 /"}
            nameBlock={"booking"}
            customClassName="blockHeaderWhite"
          />
          <div className="bookingOutdoor__headerOfTable">
            <div className="leftHeader">
              <img src="/img/pictures/icon__augmented-reality_04.svg" alt="non" />
              <p>Operation Mindfall</p>
            </div>
            <div className="rightHeader">
              <div className="difficultyTable">
                <p>Difficulty </p>
                <div>{countOfDifficultDots()}</div>
              </div>
              {ScheduleAdd.accessibility.length > 0 ? (
                <div className="accessibilityTables">
                  <p>Accessibility:</p>
                  <div>
                    {ScheduleAdd.accessibility.map((feature, index) => (
                      <img key={index} src={feature.image} alt={feature.title} />
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Element name="linkOutdoorBookingComponent">
          {isFetching ? (
            <div>
              <Loader className={"loaderMiniVisible"} />
              <div
                className="bookingOutdoor__tableBooking"
                dangerouslySetInnerHTML={{ __html: htmlContent5 }}
              />
            </div>
          ) : (
            <div>
              <Loader className={"loaderMiniInvisible"} />
              <div
                className="bookingOutdoor__tableBooking"
                dangerouslySetInnerHTML={{ __html: htmlContent5 }}
              />
            </div>
          )}
              </Element>
        </div>
      </div>

  );
};

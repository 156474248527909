import React from "react";
import "./blockHeaders.scss";

export const BlockHeader = ({ numberBlock, nameBlock, customClassName }) => {
  return (
    <div>
      <div className={`${customClassName}`}>
        <p className={`${customClassName}__num`}>{numberBlock}</p>
        <h2 className={`${customClassName}__name`}>{nameBlock}</h2>
      </div>
    </div>
  );
};

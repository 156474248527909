import { Element } from "react-scroll";
import "./aboutCompany.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const AboutCompany = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const textBlocks = [
    {
      title: "Escape within 60 minutes",
      text: "`The principle of the game is simple, you are locked in a room with your team and your goal is to manage to escape within 60 minutes.",
      imageUrl: "/img/logo/dpmobile.png",
    },
    {
      title: "Engaging experience",
      text: "Unlike any other escape room venue, Breakin’ offers the most interactive and engaging experiences by bringing you into a world of mystery where every aspect of the story has been carefully designed so as to become a memory you will never forget!",
      imageUrl: "/img/logo/dpmobile2.png",
    },
    {
      title: "Look around",
      text: "You need to use all the resources and clues you find available in the room. Every object, every image, every sign could lead you to the next step and play a role in finding your way out.",
      imageUrl: "/img/logo/dpmobile3.png",
    },
    {
      title: "Teamwork",
      text: "The game can be played by groups of 2 to 6 people, the minimum age requirement being 16 for unaccompanied minors. This is the perfect team activity for colleagues wishing to spend some time outside the office, families and groups of friends who want to have a great time!",
      imageUrl: "/img/logo/dpmobile4.png",
    },
    {
      title: "Use your mind",
      text: "By using your mind and your group’s collective intelligence you must make sense of all the information and make your way through the storyline so as to not get stuck and manage to exit the room before the time runs out.",
      imageUrl: "/img/logo/dpmobile5.png",
    },
    {
      title: "Ask for help",
      text: "If you get stuck at a certain point, don’t panic, you can ask for clues! Just bear in mind that if you exceed 3 clues during a game that you cannot make it onto the fabled Breakin’ Leaderboard. Ask your Games Master for more information before your game!",
      imageUrl: "/img/logo/dpmobile6.png",
    },
  ];

  const happy_customers = (Math.round(((Math.floor(Date.now() / 1000) - 1680296400)/1580 + 60000))).toLocaleString(
    undefined,
    { minimumFractionDigits: 0 }
);

  return (
    <Element name="aboutCompanyComponent">
      <div className="aboutCompany">
        <div className="aboutCompany__header">
          <p className="aboutCompany__header--head">ABOUT THE GAME</p>
          <p className="aboutCompany__header--text">
            THE RULES ARE SIMPLE, ESCAPING IS HARD
          </p>
        </div>
        <div className="aboutMobile">
          <Slider {...settings}>
            {textBlocks.map((block, index) => (
              <div key={index}>
                <img
                  className="aboutMobile--image"
                  src={block.imageUrl}
                  alt=""
                />
                <h1>{block.title}</h1>
                <p>{block.text}</p>
              </div>
            ))}
          </Slider>
        </div>

        <div className="aboutCompany__elements">
          <div className="aboutCompany__elements__element">
            <span className="aboutCompany__elements__element--header">
              <p className="aboutCompany__elements__element--upperheader">
                Escape within 60 minutes
              </p>
            </span>
            <p className="aboutCompany__elements__element--text">
              The principle of the game is simple,
              <br />
              you are locked in a room with your
              <br />
              team and your goal is to manage to
              <br />
              escape within 60 minutes.
            </p>
          </div>
          <div className="aboutCompany__elements__element">
            <p className="aboutCompany__elements__element--header">
              Engaging experience
            </p>
            <p className="aboutCompany__elements__element--text">
              Unlike any other escape room venue, Breakin’ offers the most
              interactive
              <br />
              and engaging experiences by bringing you into a world of mystery
              where
              <br />
              every aspect of the story has been carefully designed so as to
              become a<br />
              memory you will never forget!
            </p>
          </div>
          <div className="aboutCompany__elements__element">
            <p className="aboutCompany__elements__element--header">
              Look around
            </p>
            <p className="aboutCompany__elements__element--text">
              You need to use all the resources and clues you find available in
              the room. <br />
              Every object, every image, every sign could lead you to the next
              step and <br />
              play a role in finding your way out.
            </p>
          </div>
          <div className="aboutCompany__elements__element">
            <p className="aboutCompany__elements__element--header">Teamwork</p>
            <p className="aboutCompany__elements__element--text">
              The game can be played by groups of 2 to 6 people, the minimum age{" "}
              <br />
              requirement being 16 for unaccompanied minors. This is the perfect
              team <br />
              activity for colleagues wishing to spend some time outside the
              office, <br />
              families and groups of friends who want to have a great time!
            </p>
          </div>
          <div className="aboutCompany__elements__element">
            <p className="aboutCompany__elements__element--header">
              Use your mind
            </p>
            <p className="aboutCompany__elements__element--text">
              By using your mind and your group’s collective intelligence you
              must <br />
              make sense of all the information and make your way through the{" "}
              <br />
              storyline so as to not get stuck and manage to exit the room
              before the <br />
              time runs out.
            </p>
          </div>
          <div className="aboutCompany__elements__element">
            <p className="aboutCompany__elements__element--header">
              Ask for help
            </p>
            <p className="aboutCompany__elements__element--text">
              If you get stuck at a certain point,
              <br />
              don’t panic, you can ask for clues!
              <br />
              Just bear in mind that if you exceed 3 clues
              <br />
              during a game that you cannot make it onto
              <br />
              the fabled Breakin’ Leaderboard.
              <br />
              Ask your Games Master for more
              <br />
              information before your game!
              <br />
            </p>
          </div>
          <img
            src="/img/pictures/desprejocbg.png"
            alt="elem"
            className="aboutCompany__elements__element--imgage"
          />
        </div>
      </div>

      <div className="who-we-are">
        <div className="who-we-are_head">
          <p className="we-head">Who we are</p>
        </div>

        <div className="property">
          <ul className="property-list">
            <li>
              <p className="property-imageRound"></p>
              <p className="property-imageSquare"></p>
              <div className="propery-info">
                <p className="propery-info_number1">6 000</p>
                <p className="propery-info_desc">sq.m.</p>
                <p className="propery-info_additional">
                  of Hyper Realistic Rooms
                </p>
              </div>
            </li>
            <li>
              <p className="property-imageRound"></p>
              <p className="property-imageMan"></p>
              <div className="propery-info">
                <p className="propery-info_number2">{happy_customers}</p>
                <p className="propery-info_desc">happy customers</p>
              </div>
            </li>
            <li>
              <p className="property-imageRound"></p>
              <p className="property-imageClock"></p>
              <div className="propery-info">
                <p className="propery-info_number3">60+</p>
                <p className="propery-info_desc">Minutes</p>
                <p className="propery-info_additional">of Exhilarating Fun</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Element>
  );
};

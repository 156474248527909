import { AboutUsNewComponent } from "./AboutUsNewComponent";

export const AboutUsNewList = () => {
  const aboutUsNewData = [
    {
      imageIcon: "/img/logo/time-01.svg",
      headerAbout: "Escape within 60 minutes",
      textAbout:
        "The principle of the game is simple, you are locked in a room with your team and your goal is to manage to escape within 60 minutes.",
    },
    {
      imageIcon: "/img/logo/time-01 copy.svg",
      headerAbout: "Engaging experience",
      textAbout:
        "Unlike any other escape room venue, Breakin’ offers the most interactive and engaging experiences by bringing you into a world of mystery where every aspect of the story has been carefully designed so as to become a memory you will never forget!",
    },
    {
      imageIcon: "/img/logo/time-01 copy 2.svg",
      headerAbout: "Teamwork",
      textAbout:
        "The game can be played by groups of 2 to 6 people, the minimum age requirement being 16 for unaccompanied minors. This is the perfect team activity for colleagues wishing to spend some time outside the office, families and groups of friends who want to have a great time!",
    },
    {
      imageIcon: "/img/logo/time-01 copy 3.svg",
      headerAbout: "Ask for help",
      textAbout:
        "If you get stuck at a certain point, don’t panic, you can ask for clues! Just bear in mind that if you exceed 3 clues during a game that you cannot make it onto the fabled Breakin’ Leaderboard. Ask your Games Master for more information before your game!",
    },
    {
      imageIcon: "/img/logo/time-01 copy 5.svg",
      headerAbout: "Use your mind",
      textAbout:
          "By using your mind and your group’s collective intelligence you must make sense of all the information and make your way through the storyline so as to not get stuck and manage to exit the room before the time runs out.",
    },
    {
      imageIcon: "/img/logo/time-01 copy 4.svg",
      headerAbout: "Look around",
      textAbout:
        "You need to use all the resources and clues you find available in the room. Every object, every image, every sign could lead you to the next step and play a role in finding your way out.",
    }
  ];
  return (
    <div className="wrapperAboutUsNew">
      <div className="wrapperAboutUsNew__upper">
        <p className="wrapperAboutUsNew__upper--num">01 /</p>
        <header className="wrapperAboutUsNew__upper--header">ABOUT <br/> THE GAME</header>
        <p className="wrapperAboutUsNew__upper--paragraph">
          The rules are simple, escaping is hard!
        </p>
      </div>

      <section className="aboutUsNewComponent">
        <p className="aboutUsNewComponent--bord"></p>

        {aboutUsNewData.map((block, index) => (
          <AboutUsNewComponent key={index} aboutUsNewData={block} />
        ))}
      </section>
    </div>
  );
};

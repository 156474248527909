import { OutdoorRoom } from "../components/roomComponent/OutdoorRoom";

export const OutdoorMap = ({ bookLinkButton }) => {
  const outdoorRoomData = [
    {
      roomHash: "outdoor",
      imageClass: "heistImg",
      imageUrl: "/img/pictures/icon__augmented-reality_04.svg",
      imageMobile: "/img/pictures/opermind_sm.jpg",
      title: "Operation Mindfall",
      description: [
        `We are W.I.S.E.: an independent, international intelligence organisation, operating at the highest level of secrecy to protect the world from danger.`,
        `Our sources report that the secret research company, Spider Technologies, has developed a virus for mind control and has already infected 20% of the world population. Whether it be shopping behaviour, political attitudes, or even assassinations – people can be influenced against their will. Needless to say, this is an incredible power.`,
        `To stop Spider Tech, we created Operation Mindfall and chose you as our agents. Your task: Obtain the antidote to stop Spider Tech! You only have a limited amount of time – can you do it?`,
        `*Adults must accompany children on all outdoor games.`,
      ],
      difficulty: 3,
      bcgColor: "#765da9",
      puzzleColor: "whitePuzzle",
      mobileTextWrapper: "mobileTextWrapper-purple",
    },
  ];

  return (
    <div className="roomMap">
      {/* <BlockHeader
        numberBlock={"03 /"}
        nameBlock={"rooms"}
        customClassName="blockHeader"
      /> */}
      {outdoorRoomData.map((room, index) => (
        <OutdoorRoom key={index} outdoorRoomData={room} bookLinkButton={bookLinkButton} />
      ))}
    </div>
  );
};

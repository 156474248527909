import { Introduce } from "../components/introduce/Introduce";
import { PriceBlock } from "../components/priceBlock/PriceBlock";
import { PhotoBox } from "../components/photoBox/PhotoBox";
import { Accordion } from "../components/accordion/Accordion";
import { Footer } from "../components/footer/Footer";
import { ContactInfo } from "../components/contactInfo/ContactInfo";
import { RoomMap } from "../components/roomComponent/RoomMap";
import { Booking } from "../components/booking/Booking";
import { AboutCompany } from "../components/aboutCompany/AboutCompany";
import { AboutUsNewList } from "../components/aboutUsNewComponent/AboutUsNewList";
import { useEffect, useState } from "react";

// import { NewAboutUs } from "../components/newAboutUs/NewAboutUs";
// import { animateScroll as scroll } from 'react-scroll';

export const HomePage = ({
  renderIsNeed,
  setRenderIsNeed,

  dateModalIsOpen,
  openDateModal,
  closeDateModal,

  modalIsOpen,
  openModal,
  closeModal,

  mobileMenuIsOpen,
  openMobileMenuModal,
  closeMobileMenuModal,

  videoIsOpen,
  openVideoModal,
  closeVideoMenuModal,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Introduce
        dateModalIsOpen={dateModalIsOpen}
        openDateModal={openDateModal}
        closeDateModal={closeDateModal}
        modalIsOpen={modalIsOpen}
        openModal={openModal}
        closeModal={closeModal}
        mobileMenuIsOpen={mobileMenuIsOpen}
        openMobileMenuModal={openMobileMenuModal}
        closeMobileMenuModal={closeMobileMenuModal}
        videoIsOpen={videoIsOpen}
        openVideoModal={openVideoModal}
        closeVideoMenuModal={closeVideoMenuModal}
        bookLinkButton={"linkBookingComponent"}
        // scrollToTop={scrollToTop}
      />

      {windowWidth > 414 ? <AboutUsNewList /> : <AboutCompany />}

      {/* <AboutCompany /> */}
      {/* <AboutUsNewList/> */}
      {/* <NewAboutUs /> */}
      <RoomMap bookLinkButton={"linkBookingComponent"} />
      <PriceBlock />
      <PhotoBox />
      <Booking
        renderIsNeed={renderIsNeed}
        setRenderIsNeed={setRenderIsNeed}
        dateModalIsOpen={dateModalIsOpen}
        openDateModal={openDateModal}
        closeDateModal={closeDateModal}
        link={"linkBookingComponent"}
      />
      <Accordion />
      <ContactInfo map={true}/>
      <Footer />
    </>
  );
};

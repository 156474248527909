import "./roomComponent.scss";
import { Button } from "../UI/button/Button";
import { ButtonLink } from "../UI/button/ButtonLink";
import { Element } from "react-scroll";
import { useState } from "react";

export const RoomComponent = ({ roomData, bookLinkButton }) => {
  const style = {
    backgroundColor: roomData.bcgColor,
  };

  const renderPuzzles = (difficulty, puzzleColor) => {
    let puzzles = [];
    for (let i = 0; i < 5; i++) {
      if (i < difficulty) {
        puzzles.push(<p key={i} className={puzzleColor}></p>);
      } else {
        puzzles.push(
          <p
            key={i}
            className={
              puzzleColor === "whitePuzzle" ? "opacityPuzzle" : "greyPuzzle"
            }
          ></p>
        );
      }
    }
    return puzzles;
  };
  const renderDifficulty = (difficulty) => {
    let difficultyImages = [];
    for (let i = 0; i < 5; i++) {
      if (i < difficulty) {
        difficultyImages.push(<img key={i} src="/img/logo/dotact2.png" alt="fullDot" />);
      } else {
        difficultyImages.push(<img key={i} src="/img/logo/dotina2.png" alt="emptyDot" />);
      }
    }
    return difficultyImages;
  };

  const [showText, setShowText] = useState(false);
  const initialParagraphs = 2;

  const displayedParagraphs = showText
    ? roomData.description
    : roomData.description.slice(0, initialParagraphs);

  return (
    <Element name="roomsComponent">
      <div className="rommsBox">
        
        <section
          className={roomData.roomHash}
          data-background={roomData.background}
        >
          <div className="contentOfRooms">
            <div className="paragraph">
              <h2 className="slider" data-delay="0">
                <span>
                  <img src={roomData.imageUrl} alt="Room" />
                </span>
                {roomData.title}
              </h2>
              {roomData.description.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
            <div className="roomButtons">
              <ButtonLink
                text={"Book This Room"}
                className={"bookingRoom"}
                targetComponent={`${bookLinkButton}`}
              />
              {/* {roomData.roomHash === "wizarding" ? (
                <Button text={"Play Trailer"} className={"bookingRoom playTrailerButton"} />
              ) : (
                ""
              )} */}
            </div>
            <div className="difficulty">
              <p>Difficulty: </p>
              <div className="difficulty--images">
                {renderDifficulty(roomData.difficulty)}
                {/*<img src="/img/logo/dotact2.png" alt="fullDot" />
                <img src="/img/logo/dotact2.png" alt="fullDot" />
                <img src="/img/logo/dotact2.png" alt="fullDot" />
                <img src="/img/logo/dotina2.png" alt="emptyDot" />*/}
              </div>
              <p>
                Capacity: <b>2-6 players</b>
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="roomMob" style={style}>
        <section>
          <img
            src={roomData.imageMobile}
            className={roomData.imageClass}
            alt="wizz"
          />
          <div className="descriptionOfRoom">
            <div className="difficultyMob" style={style}>
              <div className="difficulPosition">
                <div className="difficultyGroup">
                  {renderPuzzles(roomData.difficulty, roomData.puzzleColor)}
                </div>
                <span
                  className={
                    roomData.puzzleColor === "pinkPuzzle"
                      ? "blackDifficult"
                      : "whiteDifficult"
                  }
                >
                  difficulty
                </span>
              </div>
            </div>

            <h2
              className={
                roomData.bcgColor === "white" ? "blackTitle" : "whiteTitle"
              }
            >
              {roomData.title}
            </h2>

            <div
              className={`${roomData.mobileTextWrapper} ${
                !showText ? "fade-out" : ""
              }`}
            >
              <div className="mobile-text">
                {displayedParagraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>

            <button
              onClick={() => setShowText(!showText)}
              className={`showButton ${
                roomData.mobileTextWrapper === "mobileTextWrapper-white"
                  ? "black-text"
                  : "white-text"
              }`}
            >
              {showText ? "less details" : "more details"}
            </button>
            <div className="buttonsGroup">
              <ButtonLink
                text={"Book This Room"}
                className={"buttonBook"}
                targetComponent={`${bookLinkButton}`}
              />
              <span
                className={
                  roomData.puzzleColor === "pinkPuzzle"
                    ? "whiteBcgTable"
                    : "blackBcgTable"
                }
              >
                <p>2-6</p>
                players
              </span>
            </div>
          </div>
        </section>
      </div>
    </Element>
  );
};

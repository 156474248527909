import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../../loader/Loader";
import TagManager from "react-gtm-module";
import api from "../../../../core/api";
import "react-toastify/dist/ReactToastify.css";
import "..//voucher/voucher.scss";

export const PaymentSuccess = ({ acc, session_id, modalIsOpen }) => {
  const [answer, setAnswer] = useState("");
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const navigate = useNavigate();

  console.log("PaymentSuccess", session_id, acc);
  console.log("isPaymentSuccessful", isPaymentSuccessful);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (acc && session_id) {
          const response = await api.get(
            `quests/stripe-success?acc=${acc}&session_id=${session_id}`,
            {
              responseType: "text",
            }
          );

          setAnswer(response.data);
          console.log(answer, "fetchData");
        } else if (acc === "voucher") {
          setAnswer({ success: true });
          console.log("acc === voucher");
        } else {
          navigate("/");
        } // на главную страницу
      } catch (error) {
        console.error(error);
      } finally {
        window.history.replaceState({}, document.title, "/");
      }
    };
    fetchData();
  }, [acc, session_id]);

  useEffect(() => {
    if (modalIsOpen === false) {
      navigate("/");
    }
  }, [modalIsOpen, answer, navigate]);

  useEffect(() => {
    if (answer?.session_id) {
      const ecommerce = JSON.parse(answer);

      if (ecommerce.type === "booking" && ecommerce.dataLayer) {
        TagManager.dataLayer({ dataLayer: ecommerce.dataLayer });
      }
    }
  }, [answer]);

  useEffect(() => {
    if (
      answer &&
      // && answer.success === true
      !isPaymentSuccessful
    ) {
      console.log("useEffect");
      toast.success("Payment successful!");
      setIsPaymentSuccessful(true);
    }
  }, [answer]);

  return (
    <div className="voucherField">
      {console.log("render")}
      <ToastContainer />
      {answer ? (
        //  && answer.success === true
        <>
          <h1>Greetings adventurer!</h1>
          <p>We are pleased to confirm your booking at BREAKIN’ ESCAPE ROOMS LONDON</p>
        </>
      ) : answer && answer.success === false ? (
        <>
          <h1>Greetings adventurer!</h1>
          <p>Something went wrong :(</p>
        </>
      ) : (
        <Loader className={"loaderGroup"} />
      )}
    </div>
  );
};

import React from "react";
import "./contactInfo.scss";
import { Element } from "react-scroll";
import { BlockHeader } from "../blockHeaders/BlockHeader";

export const ContactInfo = ({map=false}) => {
  return (
    <Element name="contactsComponent">
      {map && (<div className="toataharta">
        <div className="sizeharta" id="map_canvas">
          <iframe
            loading="lazy"
            frameBorder="0"
            width="100%"
            height="500px"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD5s8gQIoBkrw39gy3mUwqIATisO9RiZVY&q=Breakin'+Escape+Rooms+London"
          ></iframe>
        </div>
      </div>)}
      <div className="contacts">
        <h2 className="contacts--title">Contact Details</h2>
        <h2 className="contacts--adress">
          <div className="headOfContacts">
            <p className="mainContacts">Main Contacts</p>
            <div className="openGroup">
              <p className="clockOpen"></p>
              <span>Opening Hours</span>{" "}
              <p className="clockTime">10:00 - 22:00</p>
            </div>
          </div>
          <b>Address:</b>{" "}
          <a
            href="https://goo.gl/maps/VcexaGM2ZAS2"
            target="_blank"
            rel="noopener noreferrer"
          >
            89 Holloway Rd. London, UK, N78LT
          </a>
          <br />
          <b>Mobile:</b> <a href="tel:+447484526033">07484.52.60.33</a>
          <br />
          <b>Email:</b>{" "}
          <a href="mailto:info@breakinescaperooms.co.uk">
            info@breakinescaperooms.co.uk
          </a>
        </h2>

        <h2 className="contacts--title">How To Reach Us</h2>
        <h2 className="contacts--Info">
          We are a 2 minute walk away from Highbury and Islington Station, on
          the lively Holloway Road
          <br />
          Turn left as you exit the station and keep the left side of the street
          for about 300 meters.
        </h2>

        <h2 className="contacts--howToReach">
          <div>
            <span className="icon">
              <img src="/img/logo/underground.png" alt="Underground" />
            </span>
            By tube: Victoria Line, Highbury and Islington Station
          </div>
        </h2>
        <h2 className="contacts--howToReach">
          <div>
            <span className="icon">
              <img src="/img/logo/overground.png" alt="Overground" />
            </span>
            By train: London Overground, Highbury and Islington Station
          </div>
        </h2>
        <h2 className="contacts--howToReach">
          <div>
            <span className="icon">
              <img src="/img/logo/buses.png" alt="Buses" />
            </span>
            By bus: 43, 263, 271, 393, Highbury & Islington Station (Stop A)
          </div>
        </h2>
      </div>

      {/* mobile */}

      <div className="contactsMob">
        <BlockHeader
          numberBlock={"08 /"}
          nameBlock={"Contact Details"}
          customClassName="blockHeaderWhite"
        />
        <h2 className="contactsMob__adress">
          <div className="contactsMob__adress--headOfContacts">
            <p className="mainContacts">Main Contacts</p>
            <div className="openGroup">
              <p className="clockOpen"></p>
              <span>Opening Hours</span>{" "}
              <p className="clockTime">10:00 - 22:00</p>
            </div>
          </div>
          <div className="contactsMob__adress__info">
            <div className="contactsMob__adress__info--first">
              <p>Address:</p>
              <a
                href="https://goo.gl/maps/VcexaGM2ZAS2"
                target="_blank"
                rel="noopener noreferrer"
              >
                89 Holloway Rd. London, UK, N78LT
              </a>
            </div>
            <div className="contactsMob__adress__info--second">
              <p>Mobile:</p>
              <a href="tel:+447484526033">07484.52.60.33</a>
            </div>
            <div className="contactsMob__adress__info--third">
              <p>Email:</p>
              <a href="mailto:info@breakinescaperooms.co.uk">
                info@breakinescaperooms.co.uk
              </a>
            </div>
          </div>
        </h2>

        <h2 className="contactsMob--title">
          How To <br /> Reach Us
        </h2>
        <h2 className="contactsMob--titleText">
          We are a 2 minute walk away from Highbury and Islington Station, on
          the lively Holloway Road
          <br />
          Turn left as you exit the station and keep the left side of the street
          for about 300 meters.
        </h2>

        <h2 className="contactsMob__Info">
          <span className="icon">
            <img src="/img/logo/underground.png" alt="Underground" />
          </span>
          <div>
            <p className="contactsMob__Info--InfoTitle">By tube</p>
            <p className="contactsMob__Info--InfoText">
              Victoria Line, Highbury and Islington Station
            </p>
          </div>
        </h2>
        <h2 className="contactsMob__Info">
          <span className="icon">
            <img src="/img/logo/overground.png" alt="Overground" />
          </span>
          <div>
            <p className="contactsMob__Info--InfoTitle">By train</p>
            <p className="contactsMob__Info--InfoText">
              London Overground, Highbury and Islington Station
            </p>
          </div>
        </h2>
        <h2 className="contactsMob__Info">
          <span className="icon">
            <img src="/img/logo/buses.png" alt="Buses" />
          </span>
          <div>
            <p className="contactsMob__Info--InfoTitle">By bus</p>
            <p className="contactsMob__Info--InfoText">
              43, 263, 271, 393, Highbury & Islington Station (Stop A)
            </p>
          </div>
        </h2>
      </div>
    </Element>
  );
};

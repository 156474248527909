import TagManager from "react-gtm-module";
import { HomePage } from "../pages/HomePage";
import { Route, Routes } from "react-router-dom";
import { useModal } from "../components/UI/modal/Modal.utils";
import { StripeSuccessPage } from "../pages/stripeSuccessPage";
import { StripeCancelPage } from "../pages/stripeCancelPage";
import { OutdoorPage } from "../pages/OutdoorPage";
import { CorporatePage } from "../pages/corporatePage/CorporatePage";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import "./App.scss";

const gtm_env = `${process.env.REACT_APP_GTM_ENVIRONMENT}`;
const tagManagerArgs = {
  gtmId: "GTM-59SZJTP",
  //the below changes GTM values based on whether dev or production
  auth:
    gtm_env === "production"
      ? "qqvHoRyAIAGU_-gmA_gigw"
      : "mdb0kCnyTaXnY00JxZqSxQ",
  preview: gtm_env === "production" ? "env-2" : "env-59",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  // const [renderIsNeed, setRenderIsNeed] = useState(false);
  const mainModal = useModal();
  const dateModal = useModal();
  const mobileModal = useModal();
  const videoModal = useModal();
  const paymentModal = useModal();

  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="*"
          element={
            <>
              <Helmet>
                <title>
                  Breakin Escape Rooms London | Immersive Adventures
                </title>
                <meta
                  name="description"
                  content="Discover the best escape rooms in London for an immersive adventure. Find the most exciting escape game near you and experience mind-bending puzzles. Book now!"
                />
                <meta
                  property="og:description"
                  content="Discover the best escape rooms in London for an immersive adventure. Find the most exciting escape game near you and experience mind-bending puzzles. Book now!"
                />
              </Helmet>
              <HomePage
                dateModalIsOpen={dateModal.modalIsOpen}
                openDateModal={dateModal.openModal}
                closeDateModal={dateModal.closeModal}
                modalIsOpen={mainModal.modalIsOpen}
                openModal={mainModal.openModal}
                closeModal={mainModal.closeModal}
                mobileMenuIsOpen={mobileModal.modalIsOpen}
                openMobileMenuModal={mobileModal.openModal}
                closeMobileMenuModal={mobileModal.closeModal}
                videoIsOpen={videoModal.modalIsOpen}
                openVideoModal={videoModal.openModal}
                closeVideoMenuModal={videoModal.closeModal}
              />
            </>
          }
        />
        <Route
          path="/stripe-success"
          element={
            <StripeSuccessPage
              dateModalIsOpen={dateModal.modalIsOpen}
              openDateModal={dateModal.openModal}
              closeDateModal={dateModal.closeModal}
              modalIsOpen={mainModal.modalIsOpen}
              openModal={mainModal.openModal}
              closeModal={mainModal.closeModal}
              paymentModalIsOpen={paymentModal.modalIsOpen}
              openPaymentModal={paymentModal.openModal}
              closePaymentModal={paymentModal.closeModal}
            />
          }
        />
        <Route
          path="/stripe-cancel"
          element={
            <StripeCancelPage
              dateModalIsOpen={dateModal.modalIsOpen}
              openDateModal={dateModal.openModal}
              closeDateModal={dateModal.closeModal}
              modalIsOpen={mainModal.modalIsOpen}
              openModal={mainModal.openModal}
              closeModal={mainModal.closeModal}
              paymentModalIsOpen={paymentModal.modalIsOpen}
              openPaymentModal={paymentModal.openModal}
              closePaymentModal={paymentModal.closeModal}
            />
          }
        />
        <Route
          path="/outdoor"
          element={
            <>
              <Helmet>
                <title>
                  Breakin Escape Rooms London | Operation Mindfall: AR Adventure
                  & Outdoor Fun
                </title>
                <meta
                  name="description"
                  content="Embark on an outdoor augmented reality adventure in London with Operation Mindfall at Breakin Escape Rooms. Immerse yourself in outdoor activities and scavenger hunt games combined with cutting-edge augmented reality technology. Experience the thrill of urban exploration and interactive gameplay. Book now for the best outdoor escape room adventure in London!"
                />
                <meta
                  property="og:description"
                  content="Embark on an outdoor augmented reality adventure in London with Operation Mindfall at Breakin Escape Rooms. Immerse yourself in outdoor activities and scavenger hunt games combined with cutting-edge augmented reality technology. Experience the thrill of urban exploration and interactive gameplay. Book now for the best outdoor escape room adventure in London!"
                />
              </Helmet>
              <OutdoorPage
                dateModalIsOpen={dateModal.modalIsOpen}
                openDateModal={dateModal.openModal}
                closeDateModal={dateModal.closeModal}
                modalIsOpen={mainModal.modalIsOpen}
                openModal={mainModal.openModal}
                closeModal={mainModal.closeModal}
                mobileMenuIsOpen={mobileModal.modalIsOpen}
                openMobileMenuModal={mobileModal.openModal}
                closeMobileMenuModal={mobileModal.closeModal}
              />
            </>
          }
        />
        <Route
          path="/corporate"
          element={
            <>
              <Helmet>
                <title>
                  Breakin Escape Rooms London | Unforgettable Team-Building
                  Activities
                </title>
                <meta
                  name="description"
                  content="Discover the best team building activities in London at Breakin Escape Rooms. Engage in corporate team-building activities for large groups, fostering teamwork and bonding. Book now!"
                />
                <meta
                  property="og:description"
                  content="Discover the best team building activities in London at Breakin Escape Rooms. Engage in corporate team-building activities for large groups, fostering teamwork and bonding. Book now!"
                />
              </Helmet>
              <CorporatePage
                dateModalIsOpen={dateModal.modalIsOpen}
                openDateModal={dateModal.openModal}
                closeDateModal={dateModal.closeModal}
                modalIsOpen={mainModal.modalIsOpen}
                openModal={mainModal.openModal}
                closeModal={mainModal.closeModal}
                mobileMenuIsOpen={mobileModal.modalIsOpen}
                openMobileMenuModal={mobileModal.openModal}
                closeMobileMenuModal={mobileModal.closeModal}
              />
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default App;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "../../button/Button";
import { roomSwitcher } from "../roomSwitcher";
import { Loader } from "../../loader/Loader";
import api from "../../../../core/api";
import moment from "moment";
import "./voucher.scss";

export const VoucherWithDate = ({ date, time, room, closeModal }) => {
  const [price, setPrice] = useState({});
  const [promoPrice, setPromoPrice] = useState("");
  const [selectedNumber, setSelectedNumber] = useState(2);
  const [promoCode, setPromoCode] = useState("");
  const [errors, setErrors] = useState({ promoCode: "" });
  const [loading, setLoading] = useState(false);
  const [responseBooking, setResponseBooking] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (room, date, time) => {
      if ((room, date, time)) {
        try {
          const response = await api.get(`quests/${room}/get_price?date=${date}&time=${time}`, {
            responseType: "text",
          });
          setPrice(JSON.parse(response.data));
        } catch (error) {
          console.error(error);
        }
      } else {
        closeModal();
      }
    };

    fetchData(room, date, time);
  }, [selectedNumber]);

  async function sendBookingRequest(params) {
    const bookingParams = {
      "Booking[firstName]": params.firstName,
      "Booking[lastName]": params.lastName,
      "Booking[cEmail]": params.email,
      "Booking[cPhone]": params.phone,
      "Booking[date]": date,
      "Booking[time]": time,
      "Booking[room_id]": room,
      "Booking[players_qty]": selectedNumber,
      uservoucher: params.promoCode,
    };
    const bookingURLParams = new URLSearchParams(bookingParams);
    const response = await api.post("quests/booking", bookingURLParams);
    setResponseBooking(response)
    return response;
  }

  useEffect(() => {
    const handlePromoCodeBlur = async (promoCode) => {
      const promoCodePriceParams = {
        "Booking[date]": date,
        "Booking[time]": time,
        "Booking[room_id]": room,
        "Booking[players_qty]": selectedNumber,
        uservoucher: promoCode,
      };

      if (promoCode) {
        try {
          const searchParams = new URLSearchParams(promoCodePriceParams);
          const response = await api.post("quests/check-promocode", searchParams.toString());
          setPromoPrice(response.data);
          if (response.data.success === false) {
            setErrors({ promoCode: "Promo code is Invalid" });
          } else if (response.data.success === true) {
            //setErrors({ promoCode: "Promocode is Valid" });
          }
        } catch (error) {
          console.error(errors);
        }
      }
    };
    const promoCodeValue = promoCode;
    handlePromoCodeBlur(promoCodeValue);
  }, [selectedNumber, promoCode]);

  return (
    <div className="voucherField">
      {loading && <Loader className={"loaderFullScreen"} />}
      <div>
        <h1>
          {`You are currently in the process of booking the room an ${roomSwitcher(
            room
          )} on ${moment(date, "DD-MM-YYYY").format("MMMM Do, YYYY")} at ${time}`}
        </h1>
        <p>
          Please be aware before making a booking that you must arrive at least 15 minutes before
          the time of your booking. If you are later than this time, you may lose time from your 60
          minutes inside the room and if you are over 15 minutes later than the time of your
          booking, the game will be unable to be played at all. If this is the case, the game will
          be non-refundable.
        </p>
        <div className="voucherField__form">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              number: "",
              promoCode: "",
              acceptedTerms: false,
              acceptedPolicyTerms: false,
              acceptedEligibleTerms: false,
            }}
            validate={(values) => {
              const errors = {};

              if (
                !values.acceptedTerms ||
                !values.acceptedPolicyTerms ||
                !values.acceptedEligibleTerms
              ) {
                errors.acceptedTerms = "You must agree the terms to continue";
              }

              if (!values.firstName) {
                errors.firstName = "Required field";
              } else if (values.firstName.length < 2) {
                errors.firstName = "The name must contain at least 2 characters";
              }

              if (!values.lastName) {
                errors.lastName = "Required field";
              } else if (values.lastName.length < 2) {
                errors.lastName = "The last name must contain at least 2 characters";
              }

              if (!values.email) {
                errors.email = "Required field";
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = "Invalid email address";
              }

              if (!values.phone) {
                errors.phone = "Required field";
              } else if (!/^(?:[0-9\s] ?){8,15}[0-9]$/.test(values.phone)) {
                errors.phone = "Incorrect phone number";
              }

              if (values.promoCode && promoPrice.success === false) {
                errors.promoCode = "Promo Code is Invalid";
              } else if (values.promoCode && promoPrice.success === true) {
                //errors.promoCode = "Promocode is Valid";
              } else if (!values.promoCode) {
                //errors.promoCode = null;
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setLoading(true);
              await new Promise((resolve) => setTimeout(resolve, 500));
              console.log(responseBooking, "promoPrice");
              sendBookingRequest(values)
                .then((response) => {
                  if (
                    promoPrice.total === 0 ||
                    promoPrice.payment_resourse === "voucher" ||
                    response.data.paid_by_promocode_full === true
                  ) {
                    navigate("/stripe-success?acc=voucher");
                  } else if (response.data?.payment_url && response.data.success) {
                    setLoading(false);
                    window.location.href = response.data.payment_url;
                  } else {
                    setLoading(false);
                    alert("This time has already been booked");
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  alert("Something goes wrong :(");
                });

              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="formsGroup">
                  <div className="add_group">
                    <div className="formsGroup__forms3">
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        placeholder="Enter your name"
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="formsGroup__forms--formField"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="formsGroup__forms--errorField"
                      />
                    </div>
                    <div className="formsGroup__forms3">
                      <label htmlFor="lastName">Last Name</label>
                      <Field
                        placeholder="Enter your last name"
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="formsGroup__forms--formField"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="formsGroup__forms--errorField"
                      />
                    </div>
                  </div>
                  <div className="formsGroup__forms">
                    <label htmlFor="email">Email</label>
                    <Field
                      placeholder="Enter your e-mail"
                      type="email"
                      name="email"
                      id="email"
                      className="formsGroup__forms--formField"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="formsGroup__forms--errorField"
                    />
                  </div>
                </div>
                <div className="formsGroup">
                  <div className="add_group2">
                    <div className="formsGroup__forms2">
                      <label htmlFor="phone">Phone</label>
                      <Field
                        placeholder="Enter your phone number"
                        type="tel"
                        name="phone"
                        id="phone"
                        className="formsGroup__forms--formField"
                      />

                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="formsGroup__forms--errorField"
                      />
                    </div>
                    <div className="formsGroup__forms2">
                      <label htmlFor="number">Number of Players</label>
                      {room === 5 ? (
                        <Field
                          as="select"
                          name="number"
                          id="number"
                          className="formsGroup__forms--formField"
                          value={selectedNumber}
                          onChange={(e) => setSelectedNumber(e.target.value)}
                        >
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </Field>
                      ) : (
                        <Field
                          as="select"
                          name="number"
                          id="number"
                          className="formsGroup__forms--formField"
                          value={selectedNumber}
                          onChange={(e) => setSelectedNumber(e.target.value)}
                        >
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </Field>
                      )}
                      <ErrorMessage
                        name="number"
                        component="div"
                        className="formsGroup__forms--errorField"
                      />
                    </div>
                    {date && (
                      <>
                        <div className="formsGroup__forms2">
                          <label htmlFor="promoCode">Promo Code</label>
                          <Field
                            placeholder="Enter promo code"
                            type="text"
                            name="promoCode"
                            id="promoCode"
                            className={`formsGroup__forms--formField ${
                              promoPrice.success === true
                                ? "validPromoCode"
                                : promoPrice.success === false && promoCode
                                ? "invalidPromoCode"
                                : ""
                            }`}
                            onBlur={(event) => {
                              setPromoCode(event.target.value);
                              // handlePromoCodeBlur(promoCode);
                            }}
                          />
                          <ErrorMessage
                            name="promoCode"
                            component="div"
                            className="formsGroup__forms--errorField"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="afterForm">
                  <label>
                    I agree with the{" "}
                    <a href="https://breakinescaperooms.co.uk/bundles/breakin/terms_and_conditions.pdf">
                      terms & conditions
                    </a>
                    &nbsp;
                    <Field type="checkbox" name="acceptedTerms" />
                  </label>
                  <label>
                    I agree with the{" "}
                    <a href="https://breakinescaperooms.co.uk/bundles/breakin/data-protection-policy.pdf">
                      data protection policy
                    </a>
                    &nbsp;
                    <Field type="checkbox" name="acceptedPolicyTerms" />
                  </label>
                  <label>
                    I confirm that at least one paying participant in the group is 16 years old or
                    over.&nbsp;
                    <Field type="checkbox" name="acceptedEligibleTerms" />
                  </label>
                  <ErrorMessage
                    name="acceptedTerms"
                    component="div"
                    className="formsGroup__forms-relative--errorField"
                  />
                  {price && (
                    <div className="pricesForBuy">
                      <p>Price</p>

                      {promoPrice.success === true ? (
                        <>
                          <span className="pricesForBuy--number"> £{price[selectedNumber]}</span>
                          <span>£{promoPrice.total}</span>
                        </>
                      ) : date ? (
                        <span>£{price[selectedNumber]}</span>
                      ) : (
                        <span>£{32 * selectedNumber}</span>
                      )}
                    </div>
                  )}
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    text="Checkout"
                    className={isSubmitting ? "disabledButton" : "checkoutButton"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

import { BlockHeader } from "../blockHeaders/BlockHeader";
import { RoomComponent } from "./RoomComponent";

export const RoomMap = ({bookLinkButton}) => {
  const roomData = [
    {
      roomHash: "wizarding",
      imageClass: "wizardMobImg",
      imageUrl: "/img/logo/wizzardico.png",
      imageMobile: "/img/pictures/wizard.png",
      title: " Wizarding School: Fang of the Serpent",
      description: [
        `Another year at the Wizarding School brings with it new challenges. You have been summoned by the headmaster to find a most precious artefact which has been lost for many decades.`,
        `Its whereabouts unknown, your search leads you to a mysterious part of the castle where no one has ventured in years. You feel danger lurking around every dark corner...`,

        `You and your friends must face a great evil in order to complete your mission. The monster guarding the chamber will be like nothing you’ve faced before.`,
        `Pick up your wands, remember your spells and find your courage, you will need all of them for the challenges that lie ahead!`,
      ],
      difficulty: 4,
      bcgColor: "white",
      puzzleColor: "pinkPuzzle",
      mobileTextWrapper: "mobileTextWrapper-white",
    },
    {
      roomHash: "cave",
      imageClass: "caveImg",
      imageUrl: "/img/logo/blackico.png",
      imageMobile: "/img/pictures/bitman.png",
      title: "Blackwing’s Cave",
      description: [
        `Holy padlocks, Blackwing! The evil games-master Doctor Drakker has broken out of jail and is on the loose in Knightsbane City, swearing revenge on his arch nemesis - Blackwing, dark crusader of justice.`,
        `Drakker's goons have tracked down the location of Blackwing's secret base and riddled it with an onslaught of fiendishly twisted puzzles, trapping you - Blackwing's trusty sidekicks - inside. They've hacked the security systems against you and planted an explosive surprise in the darkness...`,
        `Time is running out. Blackwing cannot battle Drakker alone. Outwit the booby-traps, defuse the bombs, escape the cave and reunite your superhero family.`,
        `So don your capes and pull on your masks - you'll need every skill in your utility belt to defeat the villain and save the city!`,
      ],
      difficulty: 4,
      bcgColor: "#765da9",
      puzzleColor: "whitePuzzle",
      mobileTextWrapper: "mobileTextWrapper-purple",
    },
    {
      roomHash: "sherlock",
      imageClass: "sherlockImg",
      imageUrl: "/img/logo/icosher.png",
      imageMobile: "/img/pictures/sherlockmob.png",
      title: "Sherlock’s Despair",
      description: [
        `21st of October 1891, London. A dark silence hangs over 221B Baker Street. The great detective Sherlock Holmes has been discovered dead, washed up in the gutters outside a seedy East End opium den.`,
        `Without his nemesis, the wicked Professor James Moriarty plots world domination from the shadows. You - Sherlock's oldest friends and faithful assistants - are now the only hope of stopping him.`,
        `You've received a mysterious telegram saying that somewhere, hidden in Sherlock's study, is a file he compiled on Moriarty's evil plans. If this is true, it must be discovered and delivered to Inspector Lestrade of Scotland Yard within the hour.`,
        `Prove your mettle as Baker Street's finest by tracking down the file, outsmarting the Napoleon of Crime, and solving the case. The plot thickens, but the game's afoot!`,
      ],
      difficulty: 5,
      bcgColor: "#737373",
      puzzleColor: "whitePuzzle",
      mobileTextWrapper: "mobileTextWrapper-gray",
    },
    {
      roomHash: "dutchman",
      imageClass: "dutchmanImg",
      imageUrl: "/img/logo/piratico.png",
      imageMobile: "/img/pictures/skulls.png",
      title: "The Flying Dutchman",
      description: [
        `Avast ye! Tell me, shark-bait, have you heard the legend of The Flying Dutchman? That dreaded ship captained by the sea-devil Davy Jones and his undead pirate crew? You'd best start believing in ghost stories... you're in one!`,

        `After your ship sinks in a great tempest you awake aboard the Dutchman. If you don't escape before sunrise you'll be trapped aboard her forever. Legend tells of a mythical diamond - the Heart of Calypso - which can break the curse. It's hidden somewhere on the lower decks.`,

        `The sun rises in an hour. So shiver your timbers, swash your buckles, and batten down the hatches. You need to discover the diamond to escape the ship and a watery doom!`,
      ],
      difficulty: 3,
      bcgColor: "black",
      puzzleColor: "whitePuzzle",
      mobileTextWrapper: "mobileTextWrapper-black",
    },
    {
      roomHash: "alpha",
      imageClass: "alphaImg",
      imageUrl: "/img/logo/warico.png",
      imageMobile: "/img/pictures/orbital.png",
      title: "War on Horizon Alpha",
      description: [
        `The galaxy is in peril, trapped under the cyborg fist of the feared Alpha One faction. The imperial regime rules from the space-fortress codenamed Horizon Alpha, a terrifying superweapon with an impenetrable security system.`,

        `You are a plucky band of rebels - the best in the parsec - chosen to infiltrate the Horizon Alpha. In 60 minutes a massive rebel fleet will arrive through hyperspace and fire on the base, but you must manually deactivate the shields if this daring assault is to work.`,

        `Grab your laser-swords. Arm your blasters. Complete the mission and escape before the Horizon Alpha is reduced to atoms. Good luck, you're our only hope!`,
      ],
      difficulty: 5,
      bcgColor: "white",
      puzzleColor: "pinkPuzzle",
      mobileTextWrapper: "mobileTextWrapper-white",
    },

    {
      roomHash: "butcher",
      imageClass: "butcherImg",
      imageUrl: "/img/logo/buico.png",
      imageMobile: "/img/pictures/butcher.png",
      title: "Butcher’s Lair",
      description: [
        `Dr Vladimir Knifesblade: university professor by day, serial killer by night. He's an educated man - he knows his classical music, his literature, and which bits of the human body taste best. The FBI have nicknamed him 'The Butcher'.`,

        `A simple dinner party invitation to you - some of his favourite students - fails to mention that you're the main ingredient on the menu tonight. You've become The Butcher's latest victims, or at least you will be if you don't escape from his bloody basement lair.`,
        `You have 60 minutes before the doctor begins cooking. Only the brightest brains survive... the rest get eaten with some fava beans and a nice Chianti.`,

        `So be smart and don't be lasagne.`,
      ],
      difficulty: 4,
      bcgColor: "#737373",
      puzzleColor: "whitePuzzle",
      mobileTextWrapper: "mobileTextWrapper-gray",
    },

    {
      roomHash: "heist",
      imageClass: "heistImg",
      imageUrl: "/img/logo/heico.png",
      imageMobile: "/img/pictures/heist.png",
      title: "Heist Plan",
      description: [
        `You are part of a gang of street racers planning a major heist in New York City. Your goal is to hit the 5 major banks in Manhattan in one night, under the cover of an illegal street race where you will be participating with two of your tuned up cars.`,
        `Everything was set for tonight but all your plans went haywire when one of your rival gangs broke into your garage, stole one of your cars and wrecked the other one.`,
        `Now you have only one hour to fix the engine of your broken car, find the car park where they have taken you second car and steal it back and then make it in time to the race and finish the job. Do you think your crew has what it takes?`
      ],
      difficulty: 5,
      bcgColor: "#765da9",
      puzzleColor: "whitePuzzle",
      mobileTextWrapper: "mobileTextWrapper-purple",
    },
  ];

  return (
    <div className="roomMap">
      <BlockHeader numberBlock={"02 /"} nameBlock={"rooms"} customClassName='blockHeader'/>
      {roomData.map((room, index) => (
        <RoomComponent key={index} roomData={room} bookLinkButton={bookLinkButton}/>
      ))}
    </div>
  );
};

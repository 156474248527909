import React from "react";
import Modal from "react-modal";
import "./modal.scss";
import { Voucher } from "./voucher/Voucher";
import { MobileMenu } from "../../mobileMenu/MobileMenu";
import { PaymentSuccess } from "./payment/PaymentSuccess";
import { PaymentFailure } from "./payment/PaymentFailure";
import { Link } from "react-router-dom";
import { VoucherWithDate } from "./voucher/VoucherWithDate";

export const CustomModal = ({
  modalIsOpen,
  closeModal,
  video,
  form,
  formWithDate,
  success,
  mobileMenu,
  videoSrc,
  contentLabel,
  className,
  overlayClassName,
  date,
  time,
  room,
  session_id,
  acc,
  cancel,
  bookLinkButton
}) => {
  const ModalType = () => {
    switch (true) {
      case video:
        return (
          <video className="modal-video" autoPlay loop controls>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case form:
        return <Voucher modalIsOpen={modalIsOpen} />;
      case formWithDate:
        return (
          <VoucherWithDate
            date={date}
            time={time}
            room={room}
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
          />
        );
      case mobileMenu:
        return <MobileMenu modalIsOpen={modalIsOpen} closeModal={closeModal} bookLinkButton={bookLinkButton}/>;
      case success:
        return (
          <PaymentSuccess
            acc={acc}
            session_id={session_id}
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
          />
        );
      case cancel:
        return (
          <PaymentFailure
            acc={acc}
            session_id={session_id}
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={contentLabel}
        className={className}
        overlayClassName={overlayClassName}
        currentPage={"outdoorModal"}
      >
        {ModalType()}
        <Link
          //  to="/"
          className="close-button"
          onClick={closeModal}
        >
          ×
        </Link>
      </Modal>
    </>
  );
};

import { Element } from "react-scroll";
import "./priceBlock.scss";
import { BlockHeader } from "../blockHeaders/BlockHeader";

export const PriceBlock = () => {
  return (
    <Element name="priceComponent">
      <div className="pricesBlock">
        <BlockHeader
          numberBlock={"03 /"}
          nameBlock={"Prices"}
          customClassName="blockHeader"
        />
        <div className="pricesBlock__group">
          <div className="pricesBlock__group--part">
            <h3>Monday - Friday</h3>

            <div className="groups">
              <div className="groups--mini">
                <div className="members">
                  <p className="members--time">09:30 – 15:45</p>
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <p className="members--players">3-6 players</p>
                  <div className="ed-prices-block">
                    <div className="ed-prices-block-cost">£27</div>
                    <div className="ed-prices-block-text">/ Person</div>
                  </div>
                </div>

                <div className="members">
                  <p className="members--time">17:30 – 21:00</p>
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <p className="members--players">3-6 players</p>
                  <div className="ed-prices-block">
                    <div className="ed-prices-block-cost">£32</div>
                    <div className="ed-prices-block-text">/ Person</div>
                  </div>
                </div>
              </div>
              <div className="groups--mini">
                <div className="members">
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <p className="members--players">2 players</p>
                  <div className="ed-prices-block">
                    <div className="ed-prices-block-cost">£35</div>
                    <div className="ed-prices-block-text">/ Person</div>
                  </div>
                </div>
                <div className="members">
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <p className="members--players">2 players</p>
                  <div className="ed-prices-block">
                    <div className="ed-prices-block-cost">£39</div>
                    <div className="ed-prices-block-text">/ Person</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pricesBlock__group--part">
            <h3>Saturday</h3>
            <div className="groups">
              <div className="groups--mini2">
                <div className="members2">
                  <p className="members2--time">09:00 - 21:00</p>
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <p className="members2--players">3-6 players</p>
                  <div className="ed-prices-block">
                    <div className="ed-prices-block-cost">£32</div>
                    <div className="ed-prices-block-text">/ Person</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pricesBlock__group--part">
            <h3>Sunday</h3>
            <div className="groups">
              <div className="groups--mini2">
                <div className="members2">
                  <p className="members2--time">09:00 - 21:00</p>
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <p className="members2--players">3-6 players</p>
                  <div className="ed-prices-block">
                    <div className="ed-prices-block-cost">£32</div>
                    <div className="ed-prices-block-text">/ Person</div>
                  </div>
                </div>
              </div>
              <div className="groups--mini2">
                <div className="members2">
                  <img src="/img/logo/usrico.png" alt="man" />
                  <img src="/img/logo/usrico.png" alt="man" />
                  <p className="members2--players">2 players</p>
                  <div className="ed-prices-block">
                    <div className="ed-prices-block-cost">£39</div>
                    <div className="ed-prices-block-text">/ Person</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricesBlock--info">
          {/* <p>
            Student Discount - Monday to Thursday all day, Fridays off-peak,
            student price 25 pounds/person (only valid with student ID for all
            players)
          </p> */}
          <p>___ Minimum 3 players on Saturdays ___</p>
        </div>
      </div>

      <div className="new-prices">
        <h2 className="pricesBlock--head">Prices</h2>
        <BlockHeader
          numberBlock={"04 /"}
          nameBlock={"Prices"}
          customClassName="blockHeaderWhite"
        />

        <div className="pricelist">
          <div className="pricelist-1">
            <div className="price-up">
              <p className="priceWeek">Monday— Friday</p>

              <div className="timeGroup">
                <p className="priceTime">9:30 – 15:45</p>
                <p className="priceClock"></p>
              </div>
            </div>
            <div className="priceTicket2">
              <div className="priceTicket-left-transparent">
                <p className="priceRound2"></p>
                <p className="priceNew">£35</p>
                <p className="pricePerson">/ Person</p>
                <div className="priceImg">
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                </div>
              </div>
              <div className="priceTicket-right">
                <p className="priceTicket-right_bold">2</p>
                <p>players</p>
              </div>
            </div>
            <div className="priceTicket">
              <div className="priceTicket-left">
                <p className="priceRound"></p>
                <p className="priceSale">Sale</p>
                <p className="priceFire"></p>
                <p className="priceNew">£27</p>
                <p className="priceOld">£32</p>
                <p className="pricePerson">/ Person</p>
                <div className="priceImg">
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                </div>
              </div>
              <div className="priceTicket-right">
                <p className="priceTicket-right_bold">3-6</p>
                <p>players</p>
              </div>
            </div>
          </div>

          <div className="pricelist-2">
            <div className="price-up">
              <p className="priceWeek">Monday— Friday</p>
              <div className="timeGroup">
                <p className="priceTime">17:30 – 21:00</p>
                <p className="priceClock"></p>
              </div>
            </div>

            <div className="priceTicket2">
              <div className="priceTicket-left-transparent">
                <p className="priceRound2"></p>
                <p className="priceNew">£39</p>
                <p className="pricePerson">/ Person</p>
                <div className="priceImg">
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                </div>
              </div>
              <div className="priceTicket-right">
                <p className="priceTicket-right_bold">2</p>
                <p>players</p>
              </div>
            </div>
            <div className="priceTicket">
              <div className="priceTicket-left">
                <p className="priceRound-lilac"></p>
                <p className="priceSale">Sale</p>
                <p className="priceFire"></p>
                <p className="priceNew">£32</p>
                <p className="priceOld">£37</p>
                <p className="pricePerson">/ Person</p>
                <div className="priceImg">
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                </div>
              </div>
              <div className="priceTicket-right">
                <p className="priceTicket-right_bold">3-6</p>
                <p>players</p>
              </div>
            </div>
          </div>

          <div className="pricelist-3">
            <div className="price-up">
              <p className="priceWeek">Saturday— Sunday</p>
              <div className="timeGroup">
                <p className="priceTime">09:00 - 21:15</p>
                <p className="priceClock"></p>
              </div>
            </div>

           

            <div className="priceTicket2">
              <div className="priceTicket-left-pink-transparent">
                <p className="priceRound2-pink"></p>
                <p className="priceNew">£39</p>
                <p className="pricePerson">/ Person</p>
                <div className="priceImg">
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                  <img className="person-opacity" src="/img/logo/miniman.png" />
                </div>
              </div>
              <div className="priceTicket-right">
                <p className="priceTicket-right_bold">2</p>
                <p>players</p>
              </div>
            </div>
            <div className="priceTicket">
              <div className="priceTicket-left-pink">
                <p className="priceRound"></p>
                <p className="priceSale">Sale</p>
                <p className="priceFire"></p>
                <div>
                  <p className="priceNew">£32</p>
                  <p className="priceOld">£37</p>
                  <p className="pricePerson">/ Person</p>
                </div>
                <div className="priceImg">
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                  <img src="/img/logo/miniman.png" />
                </div>
              </div>
              <div className="priceTicket-right">
                <p className="priceTicket-right_bold">3-6</p>
                <p>players</p>
              </div>
            </div>
          </div>
          <hr />
          <p className="paragraphOfPlayers">___ Minimum 3 players on Saturdays ___</p>
          <hr />
          {/* <div className="price_description">
            <p className="Fire"></p> */}
            {/* <p className="price_description-head">Student Discount</p>
            <p className="price_description-text">
              Monday to Thursday all day, Fridays off-peak, student price 25
              pounds/person (only valid with student ID for all players).
            </p> */}
          {/* </div> */}
        </div>
      </div>
    </Element>
  );
};

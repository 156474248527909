import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { animateScroll } from "react-scroll";

export const ButtonLink = ({ className, text, targetComponent, onClick }) => {
  const navigate = useNavigate();

  const handleLinkClick = () => {
    // if (window.location.pathname !== "/") {
    //   navigate("/");
    // }
    if (onClick) {
      onClick();
    }
    scrollToTarget();
  };

  const scrollToTarget = () => {
    animateScroll.scrollTo(targetComponent, {
      smooth: true,
      duration: 500,
      offset: -150
    });
  };

  return (
    <ScrollLink
      to={targetComponent}
      smooth={true}
      duration={500}
      spy={true}
      className={className}
      onClick={handleLinkClick}
    >
      {text}
    </ScrollLink>
  );
};
// import React from 'react';
// import { Link } from 'react-scroll';

// export const ButtonLink = ({className, text, targetComponent, onClick}) => {

//   return (
//     <Link
//       to={targetComponent}
//       smooth={true}
//       duration={500}
//       className={className}
//       onClick={onClick}
//     >
//       {text}
//     </Link>
//   );
// }

import "./accordion.scss";

export const AccordionItem = ({
  question,
  answer,
  id,
  openQuestion,
  setOpenQuestion,
}) => {
  const toggleIsOpen = (id) => {
    setOpenQuestion(openQuestion === id ? null : id);
  };

  return (
    <li className="list">
      <button className="list__Button" onClick={() => toggleIsOpen(id)}>
        <b>
          <span>{openQuestion === id ? "-" : "+"}</span> {question}
          {openQuestion === id ? (
            <img className="greyOval" src="/img/logo/grayOval.png" alt="grey" />
          ) : (
            <div className="questionPoint">
              <img
                className="questionPoint--sign"
                src="/img/logo/quest.png"
                alt="quest"
              />{" "}
              <img
                className="questionPoint--oval"
                src="/img/logo/pinkOval.png"
                alt=""
              />
            </div>
          )}
        </b>
      </button>
      <div
        className={`answerContent ${openQuestion === id ? "open" : "closed"}`}
      >
        {/* <b>
          <span></span>
        </b> */}
        {answer}
      </div>
    </li>
  );
};

import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import "./photoBox.scss";
import { Element } from "react-scroll";
import { BlockHeader } from "../blockHeaders/BlockHeader";

const images = [
  { src: "/img/pictures/sherlock-big.jpg", alt: "sherlock" },
  { src: "/img/pictures/pirates-big.jpg", alt: "pirates" },
  { src: "/img/pictures/potter big.jpg", alt: "potter" },
  { src: "/img/pictures/garage-big.jpg", alt: "garage" },
  { src: "/img/pictures/a5big.jpg", alt: "arrows" },
  { src: "img/pictures/bw-1 big.jpg", alt: "graffity" },
  { src: "img/pictures/a7 big.jpg", alt: "bar" },
  { src: "/img/pictures/a8 big.jpg", alt: "skull" },
];

Modal.setAppElement("#root");

export const PhotoBox = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [hoverImage, setHoverImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const imageRef = useRef();

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      setImageDimensions({
        width: this.naturalWidth,
        height: this.naturalHeight,
      });
    };
    img.src = images[currentImage].src;
  }, [currentImage]);

  const openModal = (index) => {
    setCurrentImage(index);
    setIsOpen(true);
  };

  // const afterOpenModal = () => {
  //   const img = imageRef.current;
  //   if (img) {
  //     const { width, height } = img.getBoundingClientRect();
  //     customStyles.content.maxWidth = `${width}px`;
  //     customStyles.content.maxHeight = `${height}px`;
  //   }
  // };

  const closeModal = () => {
    setIsOpen(false);
  };

  const nextImage = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      setCurrentImage(0);
    }
  };

  const prevImage = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    } else {
      setCurrentImage(images.length - 1);
    }
  };

  // const customStyles = {
  //   overlay: {
  //     position: "fixed",
  //     inset: 0,
  //     backgroundColor: "rgba(0, 0, 0, 0.7)",
  //     zIndex: "5",
  //   },
  //   content: {
  //     width: "56vw",
  //     height: "70vh",
  //     margin: "auto",
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     position: "relative",
  //     top: "16%",
  //   },
  // };

  return (
    <Element name="galleryComponent">
      <div className="gallery">
      <BlockHeader numberBlock={"05 /"} nameBlock={"gallery"} customClassName='blockHeaderWhite'/>
        <div className="photoBox">
          <div className="photoBox--images">
            {images.map((img, index) => (
              <div
                key={index}
                className={`photoBox--images--${index + 1} ${
                  modalIsOpen ? "no-scale" : ""
                } ${hoverImage === index ? "darken" : ""}`}
              >
                <img
                  src={img.src}
                  alt={img.alt}
                  onClick={() => openModal(index)}
                  onMouseEnter={() => setHoverImage(index)}
                  onMouseLeave={() => setHoverImage(null)}
                  className={
                    hoverImage === index && !modalIsOpen ? "darken" : ""
                  }
                />
              </div>
            ))}

            <Modal
              className={`photoBox--modal ${modalIsOpen ? "is-open" : ""}`}
              isOpen={modalIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              // style={customStyles}
              contentLabel="Image Modal"
            >
              <img
                ref={imageRef}
                src={images[currentImage].src}
                alt={images[currentImage].alt}
              />
              <button className="left" onClick={prevImage}>
                ←
              </button>
              <button className="right" onClick={nextImage}>
                →
              </button>
              <button className="close" onClick={closeModal}>
                ×
              </button>
            </Modal>
          </div>
        </div>
      </div>
    </Element>
  );
};

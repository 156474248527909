import "./aboutUsNewComponent.scss";

export const AboutUsNewComponent = ({ aboutUsNewData }) => {
  return (
    <>
      <div className="aboutUsNewComponent__block">
        <img
          className="aboutUsNewComponent__block--oval"
          src="/img/logo/Oval Copy.svg"
          alt="oval"
        />
        <img
          className="aboutUsNewComponent__block--logo"
          src={aboutUsNewData.imageIcon}
          alt="icon"
        />
               <p className="aboutUsNewComponent--bord2"></p>

        <h2 className="aboutUsNewComponent__block--header">{aboutUsNewData.headerAbout}</h2>
        <p className="aboutUsNewComponent__block--text">{aboutUsNewData.textAbout}</p>
      </div>
    </>
  );
};

import { useState } from "react";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import { NavMenu } from "../../components/navMenu/NavMenu";
import { RoomMap } from "../../components/roomComponent/RoomMap";
import { Booking } from "../../components/booking/Booking";
import { Accordion } from "../../components/accordion/Accordion";
import { ContactInfo } from "../../components/contactInfo/ContactInfo";
import { Footer } from "../../components/footer/Footer";
import { OtherCompany } from "../../components/otherCompany/OtherCompany";
import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";
import "react-toastify/dist/ReactToastify.css";
import api from "../../core/api";
import "./corporatePage.scss";

export const CorporatePage = ({
  dateModalIsOpen,
  openDateModal,
  closeDateModal,

  modalIsOpen,
  openModal,
  closeModal,

  mobileMenuIsOpen,
  openMobileMenuModal,
  closeMobileMenuModal,
}) => {
  const [disabledState, setDisabledState] = useState(null);
  const initialValues = {
    CorporateContactForm: {
      name: "",
      phone: "",
      email: "",
      potentialDates: "",
      subject: "",
      potentialTime: "",
      errors: "",
    },
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const corporateParams = {
        "CorporateContactForm[name]": values.name,
        "CorporateContactForm[phone]": values.phone,
        "CorporateContactForm[email]": values.email,
        "CorporateContactForm[potentialDates]": values.potentialDates,
        "CorporateContactForm[subject]": values.subject,
        "CorporateContactForm[potentialTime]": values.potentialTime,
      };

      const searchParams = new URLSearchParams(corporateParams);
      const response = await api.post("quests/corporate-contact", searchParams, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      if (response.status === 200) {
        if (response.data.success === true) {
          setDisabledState(true);
          toast.success("Мы получили ваш запрос и скоро свяжемся с вами!");
        } else {
          let erstr = "";
          if (response.data.errors.length > 0) {
            response.data.errors.forEach((er) => (erstr += er));
          }
          alert("Обнаружены ошибки: " + erstr);
        }
        resetForm();
      } else {
        console.error("Ошибка при отправке данных на сервер");
      }
    } catch (error) {
      console.error("Ошибка при выполнении запроса:", error);
    }
  };

  const MaskedInput = (props) => {
    const [field] = useField(props);
    return <InputMask {...field} {...props} />;
  };

  return (
    <div className="corporatePageContainer">
      <ToastContainer />
      <div className="introduceCorporate">
        <div className="wrapperCorporate">
          <NavMenu
            dateModalIsOpen={dateModalIsOpen}
            openDateModal={openDateModal}
            closeDateModal={closeDateModal}
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            mobileMenuIsOpen={mobileMenuIsOpen}
            openMobileMenuModal={openMobileMenuModal}
            closeMobileMenuModal={closeMobileMenuModal}
            bookLinkButton={"linkCorporateBookingComponent"}
          />
          <section className="introduceCorporate__section">
            <div className="introduceCorporate__section__body">
              <div className="introduceCorporate__section__body__text">
                <h1 className="introduceCorporate__section__body__text--headWhite">corporate</h1>
                <h2 className="introduceCorporate__section__body__text--headPink">bookings</h2>
                <p className="introduceCorporate__section__body__text--paragraph">
                  Taking team building experience to the next level.
                </p>
                {/*<p className="introduceCorporate__section__body__text--gray">
                  Fill out the form below and AIM team will be in touch!
                </p>*/}
              </div>
            </div>
            <div className="formbox">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                className="formCorporate"
                validate={(values) => {
                  const errors = {};

                  if (!values.name) {
                    errors.name = "Required field";
                  } else if (values.name.length < 2) {
                    errors.name = "The name must contain at least 2 characters";
                  }
                  if (!values.email) {
                    errors.email = "Required field";
                  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                    errors.email = "Invalid email address";
                  }
                  if (!values.phone) {
                    errors.phone = "Required field";
                  } else if (!/^(?:[0-9\(\)\+\s] ?){8,15}[0-9]$/.test(values.phone)) {
                    errors.phone = "Incorrect phone number";
                  }
                  if (!values.subject) {
                    errors.subject = "Required field";
                    // } else if (values.subject < 2) {
                    //   errors.subject = "Participants can be from 2 to 12 people";
                    // } else if (values.subject > 12) {
                    //   errors.subject = "Participants can be from 2 to 12 people";
                  }
                  return errors;
                }}
              >
                <Form className="formCorporate__form">
                  <div className="formCorporate__form__fields">
                    <label htmlFor="name">Name *</label>
                    <Field type="text" name="name" disabled={disabledState} />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="formCorporate__form__fields--errorField"
                    />
                  </div>
                  <div className="formCorporate__form__fields">
                    <label htmlFor="CorporateContactForm.email">Email *</label>
                    <Field type="text" name="email" disabled={disabledState} />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="formCorporate__form__fields--errorField"
                    />
                  </div>
                  <div className="formCorporate__form__fields">
                    <label htmlFor="phone">Phone *</label>
                    <Field type="text" name="phone" disabled={disabledState} />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="formCorporate__form__fields--errorField"
                    />
                  </div>
                  <div className="formCorporate__form__fields">
                    <div className="formCorporate__form__fields--mini">
                      <label htmlFor="subject">Number of Players *</label>
                      <Field
                        type="text"
                        name="subject"
                        // as="select"
                        disabled={disabledState}
                      >
                        {/* <option value="">--Choose a number--</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option> */}
                      </Field>

                      <ErrorMessage
                        name="subject"
                        component="div"
                        className="formCorporate__form__fields--errorField"
                      />
                    </div>
                    <div className="formCorporate__form__fields--mini">
                      <label htmlFor="potentialDates">Event Date</label>
                      <Field
                        type="text"
                        name="potentialDates"
                        placeholder="_ _ / _ _ / _ _"
                        as={MaskedInput}
                        mask="9 9 / 9 9 / 9 9"
                        disabled={disabledState}
                      />
                    </div>
                  </div>
                  <div className="formCorporate__form__fields">
                    <label htmlFor="CorporateContactForm.potentialTime">
                      Preferred Kick Off Time
                    </label>
                    <Field type="text" name="potentialTime" disabled={disabledState} />
                  </div>
                  <button type="submit" className="corpRequest buttonPink" disabled={disabledState}>
                    Request a price
                  </button>
                </Form>
              </Formik>
            </div>
          </section>
        </div>
      </div>
      <OtherCompany />
      <RoomMap bookLinkButton={"linkCorporateBookingComponent"} />
      <Booking
        dateModalIsOpen={dateModalIsOpen}
        openDateModal={openDateModal}
        closeDateModal={closeDateModal}
        link={"linkCorporateBookingComponent"}
      />
      <Accordion />
      <ContactInfo map={true}/>
      <Footer />
    </div>
  );
};

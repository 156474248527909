export const roomSwitcher = (arg) => {
    let roomName;
    switch (arg) {
      case 1:
        roomName = "Wizarding School: Fang of the Serpent";
        return roomName;
      case 2:
        roomName = "Blackwing’s Cave";
        return roomName;
      case 3:
        roomName = "Sherlock’s Despair #1";
        return roomName;
      case 4:
        roomName = "The Flying Dutchman";
        return roomName;
      case 5:
        roomName = "Operation Mindfall";
        return roomName;
      case 6:
        roomName = "War on Horizon Alpha";
        return roomName;
      case 7:
        roomName = "Butcher’s Lair";
        return roomName;
      case 8:
        roomName = "Heist Plan";
        return roomName;
      case 9:
        roomName = "Sherlock’s Despair #2";
        return roomName;
    }
  };
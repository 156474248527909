import './button.scss'

export const Button = ({
    text,
    className,
    onClick,
    type,
    disabled=false
}) => {

    return (
        <button
            type={type}
            className={className}
            onClick={onClick}
            disabled={disabled}
        >{text}</button>
    )
};




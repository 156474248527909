import { useEffect } from "react";
import { Loader } from "../UI/loader/Loader";

export const ScheduleList = ({ scheduleData}) => {
  const countOfDifficultDots = () => {
    let dotsArray = [];
    for (let i = 0; i < 5; i++) {
      if (i < scheduleData.difficulty) {
        dotsArray.push(
          <img key={`dotact-${i}`} src="/img/logo/dotact.png" alt="dota" />
        );
      } else if (i >= scheduleData.difficulty) {
        dotsArray.push(
          <img key={`dotina-${i}`} src="/img/logo/dotina.png" alt="dotina" />
        );
      }
    }
    return dotsArray;
  };

  return (
    <div className="bookingContainerOfEachCard">
      <div>
        <div className="headerOfTable">
          <div className="leftHeader">
            <img src={scheduleData.imageUrl} alt={scheduleData.titleOfRoom} />
            <p>{scheduleData.titleOfRoom}</p>
          </div>
          <div className="rightHeader">
            <div className="difficultyTable">
              <p>Difficulty </p>
              <div>{countOfDifficultDots()}</div>
            </div>
            {scheduleData.accessibility.length > 0 ? (
              <div className="accessibilityTables">
                <p>Accessibility:</p>
                <div>
                  {scheduleData.accessibility.map((feature, index) => (
                    <img key={index} src={feature.image} alt={feature.title} />
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {scheduleData.loaderStatus ? (
          <div>
            <Loader className={"loaderMiniVisible"} />
            <div
              className="tableBooking"
              dangerouslySetInnerHTML={{ __html: scheduleData.contentHTML }}
            />
          </div>
        ) : (
          <div>
            <Loader className={"loaderMiniInvisible"} />
            <div
              className="tableBooking"
              dangerouslySetInnerHTML={{ __html: scheduleData.contentHTML }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

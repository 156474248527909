import { useState } from "react";

import { useEffect } from "react";
import { CustomModal } from "../components/UI/modal/CustomModal";

export const StripeCancelPage = ({
  renderIsNeed,
  setRenderIsNeed,

  modalIsOpen,
  openModal,
  closeModal,

  dateModalIsOpen,
  openDateModal,
  closeDateModal,

  paymentModalIsOpen,
  openPaymentModal,
  closePaymentModal,
}) => {
  const [acc, setAcc] = useState();
  const [session_id, setSession_id] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const getAcc = urlParams.get("acc");
    // const getStripe = urlParams.get("stripe");
    const getSessionId = urlParams.get("session_id");

    setAcc(urlParams.get("acc"));
    setSession_id(urlParams.get("session_id"));
    // if (getAcc && getSessionId) {
      closeModal()
      closeDateModal()
    openPaymentModal();
    window.history.replaceState({}, document.title, "/");
    // }
  }, [openPaymentModal]);
  return (
    <CustomModal
      modalIsOpen={paymentModalIsOpen}
      closeModal={closePaymentModal}
      contentLabel={"Example Modal"}
      className={"modal-content2"}
      overlayClassName={"modal2"}
      acc={acc}
      session_id={session_id}
      renderIsNeed={renderIsNeed}
      setRenderIsNeed={setRenderIsNeed}
      cancel
    />
  );
};

import { Loader } from "../UI/loader/Loader";
import { ScheduleList } from "./ScheduleList";

export const ScheduleMap = ({
  htmlContent,
  htmlContent2,
  htmlContent3,
  htmlContent4,
  htmlContent6,
  htmlContent7,
  htmlContent8,
  htmlContent9,
  isFetching,
  isFetching2,
  isFetching3,
  isFetching4,
  isFetching6,
  isFetching7,
  isFetching8,
  isFetching9,
}) => {
  const scheduleData = [
    {
      titleOfRoom: "Wizarding School",
      imageClass: "wizardMobImg",
      imageUrl: "/img/logo/wizzardico.png",
      difficulty: 4,
      contentHTML: htmlContent,
      loaderStatus: isFetching,
      accessibility: [
        { title: "Climbing required", image: "/img/logo/Climb.svg" },
        { title: "Loud Noises", image: "/img/logo/Loud_noises.svg" },
        {
          title: "Puzzles that rely on colour vision",
          image: "/img/logo/Colour_vision.svg",
        },
      ],
    },
    {
      titleOfRoom: "War on Horizon Alpha",
      imageClass: "alphaImg",
      imageUrl: "/img/logo/warico.png",
      difficulty: 5,
      contentHTML: htmlContent6,
      loaderStatus: isFetching6,
      accessibility: [
        { title: "Flashing_Lights", image: "/img/logo/Flashing_Lights.svg" },
        { title: "Loud Noises", image: "/img/logo/Loud_noises.svg" },
      ],
    },
    {
      titleOfRoom: "Heist Plan",
      imageClass: "heistImg",
      imageUrl: "/img/logo/heico.png",
      difficulty: 5,
      contentHTML: htmlContent8,
      loaderStatus: isFetching8,
      accessibility: [
        { title: "Loud Noises", image: "/img/logo/Loud_noises.svg" },
        {
          title: "Puzzles that rely on colour vision",
          image: "/img/logo/Colour_vision.svg",
        },
      ],
    },
    {
      titleOfRoom: "Blackwing’s Cave",
      imageClass: "caveImg",
      imageUrl: "/img/logo/blackico.png",
      difficulty: 4,
      contentHTML: htmlContent2,
      loaderStatus: isFetching2,
      accessibility: [
        {
          title: "On the 1st floor of the building. No lift access",
          image: "/img/logo/No_lift_access.svg",
        },
        {
          title: "Puzzles that rely on colour vision",
          image: "/img/logo/Colour_vision.svg",
        },
        { title: "Dark Room", image: "/img/logo/Dark_Room.svg" },
      ],
    },
    {
      titleOfRoom: "The Flying Dutchman",
      imageClass: "dutchmanImg",
      imageUrl: "/img/logo/piratico.png",
      difficulty: 3,
      contentHTML: htmlContent4,
      loaderStatus: isFetching4,
      accessibility: [],
    },

    {
      titleOfRoom: "Butcher’s Lair",
      imageClass: "butcherImg",
      imageUrl: "/img/logo/buico.png",
      difficulty: 4,
      contentHTML: htmlContent7,
      loaderStatus: isFetching7,
      accessibility: [
        { title: "Flashing_Lights", image: "/img/logo/Flashing_Lights.svg" },
        { title: "Loud Noises", image: "/img/logo/Loud_noises.svg" },
        {
          title: "Puzzles that rely on colour vision",
          image: "/img/logo/Colour_vision.svg",
        },
      ],
    },
    {
      titleOfRoom: "Sherlock’s Despair  #1",
      imageClass: "sherlockImg",
      imageUrl: "/img/logo/icosher.png",
      difficulty: 5,
      contentHTML: htmlContent3,
      loaderStatus: isFetching3,
      accessibility: [
        {
          title: "Puzzles that rely on colour vision",
          image: "/img/logo/Colour_vision.svg",
        },
      ],
    },

    {
      titleOfRoom: "Sherlock’s Despair  #2",
      imageClass: "sherlockImg",
      imageUrl: "/img/logo/icosher.png",
      difficulty: 5,
      contentHTML: htmlContent9,
      loaderStatus: isFetching9,
      accessibility: [
        {
          title: "On the 1st floor of the building. No lift access",
          image: "/img/logo/No_lift_access.svg",
        },
        {
          title: "Puzzles that rely on colour vision",
          image: "/img/logo/Colour_vision.svg",
        },
      ],
    },
  ];

  return (
    <div className="bookingCard">
      {scheduleData.map((schedule, i) =>
       
          <ScheduleList
            scheduleData={schedule}
            key={i}
          />
        
      )}
    </div>
  );
};

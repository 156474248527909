import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "../../button/Button";
import api from "../../../../core/api";
import "./voucher.scss";
import { Loader } from "../../loader/Loader";

export const Voucher = ({ date, time, room }) => {
  const [selectedNumber, setSelectedNumber] = useState(2);
  const [loading, setLoading] = useState(false);

  async function sendBookingRequest(params) {
    const bookingParams = {
      "Voucher[donor_name]": params.firstName,
      "Voucher[donor_surname]": params.lastName,
      "Voucher[donor_phone]": params.phone,
      "Voucher[donor_email]": params.email,
      "Booking[date]": date,
      "Booking[time]": time,
      "Voucher[players_qty]": selectedNumber,
      "Voucher[price]": 32,
    };
    const bookingURLParams = new URLSearchParams(bookingParams);
    const response = await api.post("quests/voucher", bookingURLParams);
    return response;
  }


  return (
    <div className="voucherField">
      {loading && <Loader className={"loaderFullScreen"} />}
      <h1>You are currently in the process of booking an Escape Room voucher</h1>
      <p>
        After purchasing the voucher, you will receive a voucher code via email. Please use that
        code on the regular booking page and insert it in the Voucher Code section to make your
        booking.
      </p>
      <div className="voucherField__form">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            number: "",
            acceptedTerms: false,
            acceptedPolicyTerms: false,
            acceptedEligibleTerms: false,
          }}
          validate={(values) => {
            const errors = {};

            if (
              !values.acceptedTerms ||
              !values.acceptedPolicyTerms ||
              !values.acceptedEligibleTerms
            ) {
              errors.acceptedTerms = "You must agree the terms to continue";
            }

            if (!values.firstName) {
              errors.firstName = "Required field";
            } else if (values.firstName.length < 2) {
              errors.firstName = "The name must contain at least 2 characters";
            }

            if (!values.lastName) {
              errors.lastName = "Required field";
            } else if (values.lastName.length < 2) {
              errors.lastName = "The last name must contain at least 2 characters";
            }

            if (!values.email) {
              errors.email = "Required field";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = "Invalid email address";
            }

            if (!values.phone) {
              errors.phone = "Required field";
            } else if (!/^(?:[0-9\\s] ?){8,15}[0-9]$/.test(values.phone)) {
              errors.phone = "Incorrect phone number";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setLoading(true);
            // await new Promise((resolve) => setTimeout(resolve, 500));
            sendBookingRequest(values)
              .then((response) => {
                if (response.data?.payment_url && response.data.success) {
                  window.location.href = response.data.payment_url;
                } else {
                  alert("This time has already been booked");
                  setLoading(false);
                }
              })
              .catch((error) => { 
                alert("This game slot have been booked right now. Please set another one :(");
                console.log(error)
              });
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="formsGroup">
                <div className="add_group">
                  <div className="formsGroup__forms3">
                    <label htmlFor="firstName">First Name</label>
                    <Field
                      placeholder="Enter your name"
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="formsGroup__forms--formField"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="formsGroup__forms--errorField"
                    />
                  </div>
                  <div className="formsGroup__forms3">
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      placeholder="Enter your last name"
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="formsGroup__forms--formField"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="formsGroup__forms--errorField"
                    />
                  </div>
                </div>
                <div className="formsGroup__forms">
                  <label htmlFor="email">Email</label>
                  <Field
                    placeholder="Enter your e-mail"
                    type="email"
                    name="email"
                    id="email"
                    className="formsGroup__forms--formField"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="formsGroup__forms--errorField"
                  />
                </div>
              </div>
              <div className="formsGroup">
                <div className="add_group2">
                  <div className="formsGroup__forms2">
                    <label htmlFor="phone">Phone</label>
                    <Field
                      placeholder="Enter your phone number"
                      type="tel"
                      name="phone"
                      id="phone"
                      className="formsGroup__forms--formField"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="formsGroup__forms--errorField"
                    />
                  </div>
                  <div className="formsGroup__forms2">
                    <label htmlFor="number">Number of Players</label>
                    {room === 5 ? (
                      <Field
                        as="select"
                        name="number"
                        id="number"
                        className="formsGroup__forms--formField"
                        value={selectedNumber}
                        onChange={(e) => setSelectedNumber(e.target.value)}
                      >
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </Field>
                    ) : (
                      <Field
                        as="select"
                        name="number"
                        id="number"
                        className="formsGroup__forms--formField"
                        value={selectedNumber}
                        onChange={(e) => setSelectedNumber(e.target.value)}
                      >
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </Field>
                    )}
                    <ErrorMessage
                      name="number"
                      component="div"
                      className="formsGroup__forms--errorField"
                    />
                  </div>
                </div>
              </div>
              <div className="afterForm">
                <label>
                  I agree with the{" "}
                  <a href="https://breakinescaperooms.co.uk/bundles/breakin/terms-and-conditions-final.pdf">
                    terms & conditions
                  </a>
                  &nbsp;
                  <Field type="checkbox" name="acceptedTerms" />
                </label>
                <label>
                  I agree with the{" "}
                  <a href="https://breakinescaperooms.co.uk/bundles/breakin/data-protection-policy.pdf">
                    data protection policy
                  </a>
                  &nbsp;
                  <Field type="checkbox" name="acceptedPolicyTerms" />
                </label>
                <label>
                  I confirm that at least one paying participant in the group is 16 years old or
                  over.&nbsp;
                  <Field type="checkbox" name="acceptedEligibleTerms" />
                </label>
                <ErrorMessage
                  name="acceptedTerms"
                  component="div"
                  className="formsGroup__forms-relative--errorField"
                />

                <div className="pricesForBuy">
                  <p>Price</p>
                  <span>£{32 * selectedNumber}</span>
                </div>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  text="Checkout"
                  className={isSubmitting ? "disabledButton" : "checkoutButton"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

import { BlockHeader } from "../blockHeaders/BlockHeader";
import "./features.scss";

export const Features = () => {
  return (
    <div className="features">
      <BlockHeader
        numberBlock={"02 /"}
        nameBlock={"Key Features"}
        customClassName="blockHeader"
      />
      <div className="features__property">
        <ul className="features__property__list">
          <li className="features__property__list__li">
            <p className="features__property__list__li--number">01 /</p>
            <p className="features__property__list__li--imageRound"></p>
            <p className="features__property__list__li--imageClock"></p>
            <div className="features__property__list__li--info">
              <p className="features__property__list__li--info_number3">
                2 Hour Outdoor AR Experience
              </p>
            </div>
          </li>
          <li className="features__property__list__li">
            <p className="features__property__list__li--number">02 /</p>
            <p className="features__property__list__li--imageRound"></p>
            <p className="features__property__list__li--imageEye"></p>
            <div className="features__property__list__li--info">
              <p className="features__property__list__li--info_number1">
                Iconic & Beautiful London Sights
              </p>
            </div>
          </li>
          <li className="features__property__list__li">
            <p className="features__property__list__li--number">03 /</p>
            <p className="features__property__list__li--imageRound"></p>
            <p className="features__property__list__li--imagePuzzle"></p>
            <div className="features__property__list__li--info">
              <p className="features__property__list__li--info_number2">
                Discover City Secrets
              </p>
            </div>
          </li>
          <li className="features__property__list__li">
            <p className="features__property__list__li--number">04/</p>
            <p className="features__property__list__li--imageRound"></p>
            <p className="features__property__list__li--imageHeart"></p>
            <div className="features__property__list__li--info">
              <p className="features__property__list__li--info_number4">
                Fun Filled Calories Burner
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

import "./loader.scss";

export const Loader = ({ className = { className } }) => {
  return (
    <div>
      <div className={className}>
        <div className="image-container">
          <img className="loaderImg" src="/img/logo/loader.png" alt="load" />
        </div>
      </div>
    </div>
  );
};

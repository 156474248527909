import Slider from "react-slick";
import { BlockHeader } from "../blockHeaders/BlockHeader";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./reviews.scss";

export const Reviews = ({className}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    dotsClass: "slick-dots ",
    customPaging: (i) => <CustomDots active={i === currentSlide} />,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  const CustomDots = ({ onClick, active }) => (
    <div className="slickDiv">
      <button
        style={{
          display: "flex",
          width: "20px",
          height: "20px",
          margin: "0 5px",
          background: active ? "transparent" : "tranparent",
          borderRadius: "50%",
          border: "none",
          outline: "none",
        }}
        onClick={onClick}
      ></button>
    </div>
  );

  const textBlocks = [
    {
      title: "Great holidays!",
      text: "It's an amazing place for great time with yours friends. We got awesome emotions from that place. Moreover, we got a lot of memories for the rest of our life, I can say that, because my friends also got such emotions. Free your weekend in order to visit this astonishing place. I suggest that you should do it!",
    },
    {
      title: "A refreshing new take on the escape room experience!",
      text: "I would recommend this for both those that have tried conventional escape rooms as well as to those who haven’t- the addition of an iPad and freely roaming london really does create a fun and challenging experience for smaller groups. Would love to try another puzzle by these guys- just got to hope that the weather will be even better now that we’re approaching summer!",
    },
    {
      title: "Really great experience",
      text: "Really great escape experience, I have done many of these sorts of games and I have to say this is one of my favourites. Big shoutout to our great overseer, Josh. He was a massive help, super polite, well mannered and cute 😜.  Would definitely come again. Solid 9/10",
    },
    {
      title: "Not your typical escape room",
      text: "Fun and unique experience. Overall, it's a great way to spend a few hours with friends or family and I would highly recommend doing this instead of going to a typical escape room since it was such an amazing experience and a very well-thought-out game to play while exploring London.",
    },
    {
      title: "Great way to spend time with friends",
      text: "My friends were visiting from outside of London and I decided to spice things up! Truth be told - neither of us expected this to be as fun as it was! Thanks to the team for organising this and we’re definitely coming back!!",
    },
  ];

  // <div className="reviews__description">
  //       <p className="reviews__description--head">Great holidays!</p>

  return (
    <div className={`${className}`}>
      <BlockHeader
        numberBlock={"01 /"}
        nameBlock={"Customer reviews"}
        customClassName="blockHeaderWhite"
      />
      <div className={`${className}__description`}>
        <Slider {...settings}>
          {textBlocks.map((block, index) => (
            <div key={index}>
              <h1 className={`${className}__description--head`}>{block.title}</h1>
              <p>{block.text}</p>
            </div>
          ))}
        </Slider>
        <div className={`${className}__description__bottom`}>
          <img src="/img/logo/owl.png" alt="owl" />
          <span className={`${className}__description__bottom--bord`}></span>
          <p className={`${className}__description__bottom--numbers`}> </p>
          <div className={`${className}__description__bottom--stars`}>
            <img src="/img/logo/dark copy 33.svg" alt="star" />
            <img src="/img/logo/dark copy 33.svg" alt="star" />
            <img src="/img/logo/dark copy 33.svg" alt="star" />
            <img src="/img/logo/dark copy 33.svg" alt="star" />
            <img
              // className="starOpacity"
              src="/img/logo/dark copy 33.svg"
              alt="star"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import { useEffect } from "react";
import { CustomModal } from "../components/UI/modal/CustomModal";

export const StripeSuccessPage = ({
  renderIsNeed,
  setRenderIsNeed,

  closeModal,

  closeDateModal,

  paymentModalIsOpen,
  openPaymentModal,
  closePaymentModal,
}) => {
  const [acc, setAcc] = useState();
  const [session_id, setSession_id] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setAcc(urlParams.get("acc"));
    setSession_id(urlParams.get("session_id"));
    closeModal();
    closeDateModal();
    openPaymentModal();
  }, [openPaymentModal, closeDateModal, closeModal]);

  return (
    <CustomModal
      modalIsOpen={paymentModalIsOpen}
      closeModal={closePaymentModal}
      contentLabel={"Example Modal"}
      className={"modal-content2"}
      overlayClassName={"modal2"}
      acc={acc}
      session_id={session_id}
      renderIsNeed={renderIsNeed}
      setRenderIsNeed={setRenderIsNeed}
      success
    />
  );
};

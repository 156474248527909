import { useEffect, useState } from "react";
import { Button } from "../UI/button/Button";
import { ButtonLink } from "../UI/button/ButtonLink";
import { useRef } from "react";
import { easeIn, motion } from "framer-motion";
import { CustomModal } from "../UI/modal/CustomModal";
import { Link, useNavigate } from "react-router-dom";
import "./navMenu.scss";

export const NavMenu = ({
  dateModalIsOpen,
  openDateModal,
  closeDateModal,

  modalIsOpen,
  openModal,
  closeModal,

  mobileMenuIsOpen,
  openMobileMenuModal,
  closeMobileMenuModal,

  bookLinkButton
}) => {
  const navigate = useNavigate();

  console.log('navmenu', bookLinkButton)

  const scrollUpTarget = () => {
    window.scrollTo(0, 0);
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const myRef = useRef(null);

  const handleClick = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 70);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLinkClick = () => {
    if (window.location.pathname !== "/") {
      navigate("/");
    }
    // handleModalClose();
    closeModal();
    window.scrollTo(0, 0);
  };

  return (
    <>
      {}
      <div>
        {modalIsOpen || mobileMenuIsOpen || dateModalIsOpen ? (
          ""
        ) : (
          <div className="navMenuWrapper" ref={myRef}>
            <motion.div
              className={
                isScrolled ? "sticky" : "navMenu"
              }
            >
              <div className="navMenuBlock">
                <img src="/img/logo/logo-breakin.svg" alt="breakin" onClick={handleClick}></img>

                <div className="navMenuBlock--link">
                  <ButtonLink
                    text={"Home"}
                    className={"navMenuBlock--link--item"}
                    targetComponent={"homeComponent"}
                    onClick={handleLinkClick}
                  />
                  <Link to="/outdoor" className="navMenuBlock--link--item" onClick={scrollUpTarget}>
                    Outdoor
                  </Link>
                  <Link
                    to="/corporate"
                    className="navMenuBlock--link--item"
                    onClick={scrollUpTarget}
                  >
                    Corporate
                  </Link>

                  <ButtonLink
                    text={"Who we are"}
                    className={"navMenuBlock--link--item"}
                    targetComponent={"aboutCompanyComponent"}
                    onClick={handleLinkClick}
                  />
                  <ButtonLink
                    text={"Rooms"}
                    className={"navMenuBlock--link--item"}
                    targetComponent={"roomsComponent"}
                    onClick={handleLinkClick}
                  />
                  <ButtonLink
                    text={"FAQ"}
                    className={"navMenuBlock--link--item"}
                    targetComponent={"accordionComponent"}
                    onClick={handleLinkClick}
                  />
                  <ButtonLink
                    text={"Prices"}
                    className={"navMenuBlock--link--item"}
                    targetComponent={"priceComponent"}
                    onClick={handleLinkClick}
                  />
                  <ButtonLink
                    text={"Gallery"}
                    className={"navMenuBlock--link--item"}
                    targetComponent={"galleryComponent"}
                    onClick={handleLinkClick}
                  />
                  <ButtonLink
                    text={"Contact"}
                    className={"navMenuBlock--link--item"}
                    targetComponent={"contactsComponent"}
                    onClick={handleLinkClick}
                  />
                </div>
                <div className="minButtonsLeftPart">
                  <div className="navMenuBlock--buttons">
                    <Button text={"Voucher"} onClick={openModal} className={"buttonTransparent"} />
                    <ButtonLink
                      text={"Bookings"}
                      className={"buttonPinkLink"}
                      targetComponent={`${bookLinkButton}`}
                      // onClick={handleLinkClick}
                    />
                  </div>

                  <div className="navbar-header">
                    <button
                      type="button"
                      onClick={openMobileMenuModal}
                      className="navbar-toggle"
                    ></button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </div>
      <CustomModal
        modalIsOpen={mobileMenuIsOpen}
        closeModal={closeMobileMenuModal}
        contentLabel={"Example Modal"}
        className={"modal-content2"}
        overlayClassName={"modal2"}
        bookLinkButton={bookLinkButton}
        mobileMenu
      />
      <CustomModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        contentLabel={"Example Modal"}
        className={"modal-content2"}
        overlayClassName={"modal2"}
        form
      />
    </>
  );
};
